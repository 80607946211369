import { IParticipant } from '@shared/interfaces';
import { FunctionComponent, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { Stack } from '@mui/system';
import { EMAIL_REGEX } from '../../../../config';
import JWTContext from '../../../JWTContext';
import { useQueryClient } from '@tanstack/react-query';
import { useCreateParticipant, useUpdateParticipant } from '../../../../queries/participants';
import { useEventSettings } from '../../../Context/EventSettingsContext';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
} from '@mui/material';
import { AlertContext } from '../../../AlertSnackbar/AlertContext';

interface IParticipantFormProps {
  participant: IParticipant | null;
  handleClose: () => void;
}

const ParticipantForm: FunctionComponent<IParticipantFormProps> = ({ handleClose, participant }) => {
  const [emailAddress, setEmailAddress] = useState(participant?.email ?? '');
  const [firstName, setFirstName] = useState(participant?.firstName ?? '');
  const [lastName, setLastName] = useState(participant?.lastName ?? '');
  const [title, setTitle] = useState(participant?.title ?? '');
  const { getJWT } = useContext(JWTContext);
  const queryClient = useQueryClient();
  const { enqueueAlert } = useContext(AlertContext);

  const { eventSettings } = useEventSettings();

  const { t } = useTranslation();

  const {
    mutate: createParticipant,
    error: createError,
    isPending: createPending,
  } = useCreateParticipant(getJWT, queryClient, eventSettings?.eventId);

  const {
    mutate: updateParticipant,
    error: updateError,
    isPending: updatePending,
  } = useUpdateParticipant(getJWT, queryClient, eventSettings?.eventId);

  const emailAddressIsInvalid = emailAddress.length > 0 && !emailAddress.match(EMAIL_REGEX);

  const currentAction = participant ? 'edit' : 'create';

  const formHasErrors = () => {
    return emailAddressIsInvalid || [firstName, lastName].some((item) => item.trim().length === 0);
  };

  const CreateActions = (
    <>
      <Button variant="outlined" onClick={() => handleSave()} disabled={formHasErrors() || createPending} type="submit">
        {t(`eventDetails.settings.tabs.participants.editor.saveAndClose`)}
      </Button>
      <Button
        variant="contained"
        onClick={() => handleSave(true)}
        disabled={formHasErrors() || createPending}
        type="submit"
      >
        {t(`eventDetails.settings.tabs.participants.editor.saveAndAddMore`)}
      </Button>
    </>
  );

  const EditActions = (
    <Button variant="contained" onClick={() => handleSave()} disabled={formHasErrors() || updatePending} type="submit">
      {t(`eventDetails.settings.tabs.participants.editor.update`)}
    </Button>
  );

  const resetForm = () => {
    setEmailAddress('');
    setFirstName('');
    setLastName('');
    setTitle('');
  };

  const handleSave = (addMore = false) => {
    if (!eventSettings?.eventId) {
      return;
    }

    if (currentAction === 'create') {
      createParticipant(
        {
          eventId: eventSettings.eventId,
          participant: {
            email: emailAddress,
            firstName,
            lastName,
            title,
          },
        },
        {
          onSuccess: () => {
            enqueueAlert({
              message: t('eventDetails.settings.tabs.participants.alerts.createSuccess'),
              type: 'success',
            });
            resetForm();
            if (!addMore) {
              handleClose();
            }
          },
        }
      );
    }

    if (currentAction === 'edit' && participant) {
      updateParticipant(
        {
          eventId: eventSettings.eventId,
          participant: {
            email: emailAddress,
            firstName,
            lastName,
            title,
          },
          participantId: participant.id,
        },
        {
          onSuccess: () => {
            enqueueAlert({
              message: t('eventDetails.settings.tabs.participants.alerts.updateSuccess'),
              type: 'success',
            });
            resetForm();
            handleClose();
          },
        }
      );
    }
  };

  return (
    <Dialog open={true}>
      <DialogTitle>{t(`eventDetails.settings.tabs.participants.editor.${currentAction}`)}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <DialogContentText>
          {createError && (
            <Alert sx={{ mb: 3 }} severity="error">
              {t('eventDetails.settings.tabs.participants.editor.errorCreate')}
            </Alert>
          )}
          {updateError && (
            <Alert sx={{ mb: 3 }} severity="error">
              {t('eventDetails.settings.tabs.participants.editor.errorUpdate')}
            </Alert>
          )}
        </DialogContentText>
        <Stack spacing={2} minWidth={500}>
          <TextField
            InputProps={{ inputProps: { tabIndex: 1 } }}
            margin="normal"
            label={t('eventDetails.settings.tabs.participants.table.title')}
            placeholder={t('eventDetails.settings.tabs.participants.table.title')}
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />

          <TextField
            InputProps={{ inputProps: { tabIndex: 2 } }}
            required
            margin="normal"
            label={t('eventDetails.settings.tabs.participants.table.firstName')}
            placeholder={t('eventDetails.settings.tabs.participants.table.firstName')}
            fullWidth
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />

          <TextField
            InputProps={{ inputProps: { tabIndex: 3 } }}
            required
            margin="normal"
            label={t('eventDetails.settings.tabs.participants.table.lastName')}
            placeholder={t('eventDetails.settings.tabs.participants.table.lastName')}
            fullWidth
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />

          <TextField
            error={emailAddressIsInvalid}
            helperText={
              emailAddressIsInvalid && t('eventDetails.settings.tabs.participants.editor.pleaseEnterValidEmailAddress')
            }
            InputProps={{ inputProps: { tabIndex: 4 } }}
            margin="normal"
            label={t('eventDetails.settings.tabs.participants.table.email')}
            placeholder={t('eventDetails.settings.tabs.participants.table.email')}
            fullWidth
            type="email"
            value={emailAddress}
            onChange={(e) => setEmailAddress(e.target.value)}
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>{currentAction === 'create' ? CreateActions : EditActions}</DialogActions>
    </Dialog>
  );
};

export default ParticipantForm;
