import { BookingBy } from '@shared/interfaces';
import { FunctionComponent } from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEventSettings, useEventSettingsDispatch } from '../../../Context/EventSettingsContext';
import { DefaultLabel } from '../Common/DefaultLabel';
import SectionBlock from '../Common/SectionBlock';

const TrainerAccommodationForm: FunctionComponent<unknown> = () => {
  const { t } = useTranslation();

  const { eventSettings } = useEventSettings();
  const eventSettingsDispatch = useEventSettingsDispatch();

  if (!eventSettings) return <></>;

  const { bookingBy, hotelName, hotelSuggestion } = eventSettings.trainerHotel ?? {};

  return (
    <>
      <SectionBlock>
        <DefaultLabel
          alertTitle={t('generalHintTitle')}
          label={t('eventDetails.settings.tabs.trainerAccommodation.bookingBy')}
          alertText={t('eventDetails.settings.tabs.trainerAccommodation.bookingByHint')}
        />
        <FormControl>
          <RadioGroup
            aria-labelledby="radio-buttons-feedback"
            value={bookingBy}
            name="radio-buttons-feedback"
            onChange={(event) =>
              eventSettingsDispatch({
                type: 'update',
                payload: {
                  trainerHotel: {
                    ...{ bookingBy: event.target.value as BookingBy },
                    ...(event.target.value === 'customer' ? { hotelSuggestion: undefined } : { hotelName: undefined }),
                  },
                },
              })
            }
          >
            <FormControlLabel
              value="customer"
              control={<Radio />}
              label={t('eventDetails.settings.tabs.trainerAccommodation.contactPerson')}
            />
            <FormControlLabel
              value="akademie"
              control={<Radio />}
              label={t('eventDetails.settings.tabs.trainerAccommodation.orgAkademie')}
            />
            <FormControlLabel
              value="notnecessary"
              control={<Radio />}
              label={t('eventDetails.settings.tabs.trainerAccommodation.notnecessary')}
            />
          </RadioGroup>
        </FormControl>
      </SectionBlock>
      {(bookingBy === 'customer' || bookingBy === 'akademie') && (
        <SectionBlock>
          <DefaultLabel
            alertTitle={t('generalHintTitle')}
            label={t(
              `eventDetails.settings.tabs.trainerAccommodation.${
                bookingBy === 'customer' ? 'hotelName' : 'hotelSuggestion'
              }`
            )}
            alertText={t('eventDetails.settings.tabs.trainerAccommodation.hotelHint')}
          />
          {bookingBy === 'customer' && (
            <TextField
              multiline
              value={hotelName}
              onChange={(e) =>
                eventSettingsDispatch({
                  type: 'update',
                  payload: {
                    trainerHotel: {
                      hotelName: e.target.value,
                      hotelSuggestion: undefined,
                    },
                  },
                })
              }
              margin="normal"
              fullWidth
              minRows={5}
            />
          )}
          {bookingBy === 'akademie' && (
            <TextField
              multiline
              value={hotelSuggestion}
              onChange={(e) =>
                eventSettingsDispatch({
                  type: 'update',
                  payload: {
                    trainerHotel: {
                      hotelName: undefined,
                      hotelSuggestion: e.target.value,
                    },
                  },
                })
              }
              fullWidth
              margin="normal"
              minRows={5}
            />
          )}
        </SectionBlock>
      )}
    </>
  );
};

export default TrainerAccommodationForm;
