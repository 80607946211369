import { Button } from '@mui/material';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

interface IButtonsReadOnlyParams {
  onEdit: () => void;
}

export const SectionButtonsReadOnly: FunctionComponent<IButtonsReadOnlyParams> = ({ onEdit }) => {
  const { t } = useTranslation();

  return (
    <Button variant="contained" color="primary" startIcon={<ModeEditIcon />} onClick={() => onEdit()}>
      {t('edit')}
    </Button>
  );
};
