import { QueryClient, useMutation, useQuery } from '@tanstack/react-query';
import { getApiService } from '../api/api-request';
import { ICreateParticipantRequestBody } from '@shared/interfaces';

export const participantsQueryKeys = {
  eventParticipants: (eventId?: string) => ['participants', eventId] as const,
};

type promiseReturnType = string | undefined | null;

export const useEventParticipants = (eventId: string, getJWT: () => Promise<promiseReturnType>) => {
  return useQuery({
    queryKey: participantsQueryKeys.eventParticipants(eventId),
    queryFn: () => getApiService(getJWT).getParticipants(eventId),
    enabled: Boolean(eventId),
  });
};

interface ICreateParticipantMutationParams {
  eventId: string;
  participant: ICreateParticipantRequestBody;
}

export const useCreateParticipant = (
  getJWT: () => Promise<promiseReturnType>,
  queryClient: QueryClient,
  eventId?: string,
  invalidateOnSettled = true
) => {
  return useMutation({
    mutationFn: (params: ICreateParticipantMutationParams) =>
      getApiService(getJWT).createParticipant(params.eventId, params.participant),
    onSettled: (_data, error) => {
      if (invalidateOnSettled) {
        queryClient.invalidateQueries({ queryKey: participantsQueryKeys.eventParticipants(eventId) });
      }

      if (!error) {
        // alertContext.enqueueAlert({
        //   message: 'Link erfolgreich erstellt',
        //   type: 'success',
        // });
      }
    },
    onError: () => {
      // alertContext.enqueueAlert({
      //   message: 'Fehler beim Erstellen des Links',
      //   type: 'error',
      // });
    },
  });
};

interface IUpdateParticipantMutationParams {
  eventId: string;
  participant: ICreateParticipantRequestBody;
  participantId: string;
}

export const useUpdateParticipant = (
  getJWT: () => Promise<promiseReturnType>,
  queryClient: QueryClient,
  eventId?: string,
  invalidateOnSettled = true
) =>
  useMutation({
    mutationFn: (params: IUpdateParticipantMutationParams) =>
      getApiService(getJWT).updateParticipant(params.eventId, params.participantId, params.participant),
    onSettled: (_data, error) => {
      if (invalidateOnSettled) {
        queryClient.invalidateQueries({ queryKey: participantsQueryKeys.eventParticipants(eventId) });
      }
      if (!error) {
        // alertContext.enqueueAlert({
        //   message: 'Link erfolgreich erstellt',
        //   type: 'success',
        // });
      }
    },
    onError: () => {
      // alertContext.enqueueAlert({
      //   message: 'Fehler beim Erstellen des Links',
      //   type: 'error',
      // });
    },
  });

interface IDeleteParticipantMutationParams {
  eventId: string;
  participantId: string;
}

export const useDeleteParticipant = (
  getJWT: () => Promise<promiseReturnType>,
  queryClient: QueryClient,
  eventId?: string
) =>
  useMutation({
    mutationFn: (params: IDeleteParticipantMutationParams) =>
      getApiService(getJWT).deleteParticipant(params.eventId, params.participantId),
    onSettled: (_data, error) => {
      queryClient.invalidateQueries({ queryKey: participantsQueryKeys.eventParticipants(eventId) });
      if (!error) {
        // alertContext.enqueueAlert({
        //   message: 'Link erfolgreich erstellt',
        //   type: 'success',
        // });
      }
    },
    onMutate: () => {},
    onError: () => {
      // alertContext.enqueueAlert({
      //   message: 'Fehler beim Erstellen des Links',
      //   type: 'error',
      // });
    },
  });
