import { FunctionComponent } from 'react';
import { DefaultLabel } from '../Common/DefaultLabel';
import { Trans, useTranslation } from 'react-i18next';
import { ReadonlyField } from '../Common/ReadonlyField';
import { useEventSettings } from '../../../Context/EventSettingsContext';
import SectionBlock from '../Common/SectionBlock';

const ConfirmationOfParticipationReadonly: FunctionComponent = () => {
  const { eventSettings } = useEventSettings();
  const { t } = useTranslation();

  if (!eventSettings) {
    return <></>;
  }

  const { enabled, recipientEmailAddresses } = eventSettings.confirmationOfParticipation ?? {};

  return (
    <>
      <SectionBlock>
        <DefaultLabel
          alertTitle={t('generalHintTitle')}
          label={t('eventDetails.settings.tabs.confirmationOfParticipation.certificationLabel')}
          alertText={t('eventDetails.settings.tabs.confirmationOfParticipation.certificateHint')}
        />
        {(enabled === null || enabled === undefined) &&
          t('eventDetails.settings.tabs.confirmationOfParticipation.unknownForNow')}

        {enabled === true && (
          <ReadonlyField
            text={t('eventDetails.settings.tabs.confirmationOfParticipation.enabledLabel')}
            variant="TRUE"
          />
        )}

        {enabled === false && (
          <ReadonlyField
            text={t('eventDetails.settings.tabs.confirmationOfParticipation.disabledLabel')}
            variant="FALSE"
          />
        )}
      </SectionBlock>

      {enabled && (
        <SectionBlock>
          <DefaultLabel
            alertTitle={t('generalHintTitle')}
            label={t('eventDetails.settings.tabs.confirmationOfParticipation.recipientAddressLabel')}
            alertText={
              <Trans i18nKey={'eventDetails.settings.tabs.confirmationOfParticipation.recipientAddressHint'} />
            }
          />

          <ReadonlyField
            text={recipientEmailAddresses?.[0] || t('missingData')}
            error={!recipientEmailAddresses?.[0]}
            variant="TEXT"
          />
        </SectionBlock>
      )}
    </>
  );
};

export default ConfirmationOfParticipationReadonly;
