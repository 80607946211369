import { FunctionComponent } from 'react';
import { ReadonlyField } from '../Common/ReadonlyField';
import { useEventSettings } from '../../../Context/EventSettingsContext';
import { DefaultLabel } from '../Common/DefaultLabel';
import { Trans, useTranslation } from 'react-i18next';
import AddressReadOnly from '../Common/AddressReadOnly';
import ContactPersonsReadOnly from '../Common/ContactPersonsReadOnly';
import SectionBlock from '../Common/SectionBlock';

const ContactDataReadonly: FunctionComponent = () => {
  const { eventSettings } = useEventSettings();

  if (!eventSettings) {
    return <></>;
  }

  const { t } = useTranslation();

  const { customerAddress, orderNumber, extraBillingInformation } = eventSettings.contactData ?? {};

  return (
    <>
      <SectionBlock>
        <DefaultLabel
          alertText={t('eventDetails.settings.tabs.contactData.emailAddressHint')}
          alertTitle={t('generalHintTitle')}
          label={t('eventDetails.settings.tabs.contactData.contactPersonLabel')}
          alertSeverity="info"
        />
        <ContactPersonsReadOnly />
      </SectionBlock>

      <SectionBlock>
        <DefaultLabel
          alertText={t('eventDetails.settings.tabs.contactData.billingAddressHint')}
          alertTitle={t('generalHintTitle')}
          label={t('eventDetails.settings.tabs.contactData.billingAddressLabel')}
          alertSeverity="info"
        />
        <AddressReadOnly address={customerAddress} />
      </SectionBlock>

      <SectionBlock>
        <DefaultLabel
          alertText={
            <Trans
              i18nKey={'eventDetails.settings.tabs.contactData.orderNumberHint'}
              components={{
                br: <br />,
              }}
            />
          }
          alertTitle={t('generalHintTitle')}
          label={t('eventDetails.settings.tabs.contactData.orderNumberLabel')}
          alertSeverity="info"
        />
        {orderNumber ?? <ReadonlyField variant="EMPTY" text={t('noData')} />}
      </SectionBlock>
      <SectionBlock>
        <DefaultLabel
          alertText={
            <Trans
              i18nKey={'eventDetails.settings.tabs.contactData.extraBillingInformationHint'}
              components={{
                br: <br />,
              }}
            />
          }
          alertTitle={t('generalHintTitle')}
          label={t('eventDetails.settings.tabs.contactData.extraBillingInformationLabel')}
          alertSeverity="info"
        />
        {extraBillingInformation ?? <ReadonlyField variant="EMPTY" text={t('noData')} />}
      </SectionBlock>
    </>
  );
};

export default ContactDataReadonly;
