import { Button } from '@mui/material';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import SaveButton from '../../SaveButton';
import { useEventSettingsDispatch } from '../../../../Context/EventSettingsContext';
import { IEventSettingsData } from '@shared/interfaces';

interface ISectionButtonsDefaultParams {
  onCancel: () => void;
  onSave: () => void;
  tabId?: keyof IEventSettingsData;
}

export const SectionButtonsDefault: FunctionComponent<ISectionButtonsDefaultParams> = ({ onCancel, onSave, tabId }) => {
  const eventSettingsDispatch = useEventSettingsDispatch();

  const { t } = useTranslation();

  return (
    <>
      {tabId === 'participants' ? (
        <Button
          variant="contained"
          onClick={() => {
            eventSettingsDispatch({ type: 'reset-context' });
            onCancel();
          }}
        >
          {t('close')}
        </Button>
      ) : (
        <>
          <SaveButton tabId={tabId} callBackFn={onSave} />
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              eventSettingsDispatch({ type: 'reset-context' });
              onCancel();
            }}
          >
            {t('cancel')}
          </Button>
        </>
      )}
    </>
  );
};
