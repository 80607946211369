import { FunctionComponent, useState } from 'react';
import Section from '../Common/Section';
import FeedbackForm from './FeedbackForm';
import FeedbackReadonly from './FeedbackReadonly';
import { t } from 'i18next';

const Feedback: FunctionComponent = () => {
  const [readonly, setReadonly] = useState(true);

  return (
    <Section
      setReadonly={setReadonly}
      readonly={readonly}
      tabId="feedback"
      title={t('eventDetails.settings.tabs.feedback.label')}
    >
      {readonly ? <FeedbackReadonly /> : <FeedbackForm />}
    </Section>
  );
};

export default Feedback;
