import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, Stack } from '@mui/material';
import { ChangeEvent, FunctionComponent, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { IParticipantCsvRow, parseCsvContent } from './parseCsv';
import CsvImportPreview from './CsvImportPreview';
import { IParticipant } from '@shared/interfaces';
import { AlertItem } from '../Common/AlertItem';
import { AlertContext } from '../../../AlertSnackbar/AlertContext';
import { columns } from './ParticipantsTable';

interface ICsvImportProps {
  handleClose: () => void;
  currentParticipants: IParticipant[] | undefined;
}

const CsvImport: FunctionComponent<ICsvImportProps> = ({ currentParticipants, handleClose }) => {
  const { t } = useTranslation();
  const [participantCsvRows, setParticipantCsvRows] = useState<IParticipantCsvRow[] | null>(null);
  const { enqueueAlert } = useContext(AlertContext);

  const handleFileInput = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : undefined;
    if (file && file.name.split('.').pop()?.toLowerCase() === 'csv') {
      try {
        const text = await file.text();
        const parsedResult = parseCsvContent(text, currentParticipants ?? []);

        setParticipantCsvRows(parsedResult);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        enqueueAlert({
          message: error?.message ?? t('eventDetails.settings.tabs.participants.csvImport.error'),
          type: 'error',
        });
      }
    } else {
      enqueueAlert({
        message: t('eventDetails.settings.tabs.participants.csvImport.errorFormat'),
        type: 'error',
      });
    }
  };

  const downloadTemplate = () => {
    const translatedColumns = columns.map((item) => t(`eventDetails.settings.tabs.participants.table.${item.id}`));
    const data = translatedColumns.join(';');

    const file = new Blob(['\ufeff' + data], { type: 'text/csv' });
    const url = URL.createObjectURL(file);
    const downloadLink = document.createElement('a');
    downloadLink.setAttribute('href', url);
    downloadLink.setAttribute('download', 'template.csv');
    downloadLink.style.visibility = 'hidden';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(url);
  };

  const CsvImportStart = (
    <>
      <DialogContentText mb={2}>{t('eventDetails.settings.tabs.participants.csvImport.description')}</DialogContentText>
      <AlertItem
        alertText={t('eventDetails.settings.tabs.participants.csvImport.alert')}
        alertSeverity={'info'}
        enableCloseButton={false}
        enableAlert={true}
        setEnableAlert={() => {}}
      />
      <Stack spacing={2} direction={'row'} mt={4} justifyContent={'flex-end'}>
        <Button variant="contained" component={'label'}>
          <input type={'file'} accept={'.csv'} hidden onChange={handleFileInput} />
          {t('eventDetails.settings.tabs.participants.csvImport.chooseFile')}
        </Button>
        <Button variant="outlined" onClick={() => downloadTemplate()}>
          {t('eventDetails.settings.tabs.participants.csvImport.downloadTemplate')}
        </Button>
      </Stack>
    </>
  );

  return (
    <Dialog open={true} maxWidth="md" fullWidth>
      <DialogTitle>{t(`eventDetails.settings.tabs.participants.csvImport.title`)}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        {!participantCsvRows && CsvImportStart}

        {participantCsvRows && (
          <CsvImportPreview
            participantCsvRows={participantCsvRows}
            onImportFinished={() => {
              handleClose();
              enqueueAlert({
                message: t('eventDetails.settings.tabs.participants.alerts.importCsvSuccess'),
                type: 'success',
              });
            }}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CsvImport;
