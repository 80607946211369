import { Alert, AlertTitle, Box, LinearProgress, Paper, Typography } from '@mui/material';
import { Dispatch, FunctionComponent, SetStateAction } from 'react';
import { SectionSwitch } from './SectionSwitch';
import { Sections } from '@shared/interfaces';
import { ColorPalette } from '@hmg-aka-lu/elevate-theme';
import { SectionButtonsReadOnly } from './Section/ButtonsReadOnly';
import { Stack } from '@mui/system';
import { SectionButtonsDefault } from './Section/ButtonsDefault';
import useSectionVisibility from '../../../../hooks/useSectionVisibility';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useEventSettings } from '../../../Context/EventSettingsContext';

interface ISectionProps {
  children: React.ReactNode;
  setReadonly: Dispatch<SetStateAction<boolean>>;
  readonly: boolean;
  tabId: Sections;
  showButtons?: boolean;
  title?: string;
}

const Section: FunctionComponent<ISectionProps> = ({
  children,
  setReadonly,
  readonly,
  showButtons: showButtonsProp = true,
  tabId,
  title,
}) => {
  const { isVisible } = useSectionVisibility(tabId);

  const { eventSettings } = useEventSettings();

  if (!eventSettings) {
    return <></>;
  }

  const showButtons = eventSettings.status === 'SENT_TO_CUSTOMER' ? false : showButtonsProp;

  return (
    <Box
      component={Paper}
      p={3}
      mb={3}
      elevation={0}
      position={'relative'}
      sx={{
        backgroundColor: ColorPalette.surface.elevated,
      }}
    >
      {title && (
        <Typography variant="headingSm" mt={0} mb={2} display={'flex'} justifyContent={'space-between'}>
          {title}
          {readonly && showButtons && <SectionSwitch section={tabId} />}
        </Typography>
      )}

      {!isVisible && (
        <Alert
          variant="outlined"
          icon={<VisibilityOffIcon fontSize="inherit" />}
          severity="info"
          sx={{ mb: 3, color: ColorPalette.text.primary }}
        >
          <AlertTitle>{t('eventDetails.settings.sectionSwitch.readonlyDialogTitle')}</AlertTitle>
          <Trans i18nKey={'eventDetails.settings.sectionSwitch.readonlyDialogBody'} />
        </Alert>
      )}

      {children}

      {showButtons && (
        <Stack direction={'row'} spacing={1} pt={3}>
          {readonly ? (
            <SectionButtonsReadOnly onEdit={() => setReadonly(false)} />
          ) : (
            <SectionButtonsDefault tabId={tabId} onCancel={() => setReadonly(true)} onSave={() => setReadonly(true)} />
          )}
          <LinearProgress />
        </Stack>
      )}
    </Box>
  );
};

export default Section;
