import { FunctionComponent, useContext } from 'react';
import { SummaryLabel } from '../Common/SummaryLabel';
import { useTranslation } from 'react-i18next';
import { useEventSettings } from '../../../Context/EventSettingsContext';
import { ReadonlyField } from '../Common/ReadonlyField';
import ParticipantsTable from './ParticipantsTable';
import SectionSummary from '../Common/SectionSummary';
import { IEventSettingsData } from '@shared/interfaces';
import useSectionVisibility from '../../../../hooks/useSectionVisibility';
import SectionBlock, { ISummaryNotification, NOTIFICATION_TYPE } from '../Common/SectionBlock';
import { useEventParticipants } from '../../../../queries/participants';
import JWTContext from '../../../JWTContext';

const ParticipantsSummary: FunctionComponent = () => {
  const sectionId: keyof IEventSettingsData = 'participants';
  const { isVisible } = useSectionVisibility(sectionId);
  const { t } = useTranslation();
  const { event, eventSettings, snapshots } = useEventSettings();
  const { getJWT } = useContext(JWTContext);

  const { data: participants } = useEventParticipants(eventSettings?.eventId || '', getJWT);

  if (!eventSettings || !event) return <></>;

  const { participantCount: participantCountSettings, participantCountNote } = eventSettings.participants ?? {};
  const participantCount = participantCountSettings ?? event.capacity?.max;

  const finishedByCustomerSnapshot = snapshots.find((snapshot) => snapshot.snapshotType === 'FINISHED_BY_CUSTOMER');
  const { participants: finishedByCustomerParticipants } = finishedByCustomerSnapshot ?? {};

  const getParticipantCountNotification = (): ISummaryNotification | undefined => {
    if (typeof finishedByCustomerParticipants?.participantCount !== 'undefined') {
      return {
        text: t('eventDetails.settings.tabs.summary.notifications.participants.participantCount.filled'),
        notificationType: NOTIFICATION_TYPE[0],
      };
    }

    return undefined;
  };

  const getParticipantCountNoteNotification = (): ISummaryNotification | undefined => {
    if (
      typeof finishedByCustomerParticipants?.participantCountNote !== 'undefined' &&
      finishedByCustomerParticipants.participantCountNote.length > 0
    ) {
      return {
        text: t('eventDetails.settings.tabs.summary.notifications.participants.participantCountNote.filled'),
        notificationType: NOTIFICATION_TYPE[1],
      };
    }

    return undefined;
  };

  const getRegisteredParticipantsNotification = (): ISummaryNotification | undefined => {
    if (!participants || participants.length === 0) {
      return {
        text: t('eventDetails.settings.tabs.summary.notifications.participants.participants.missingData'),
        notificationType: NOTIFICATION_TYPE[0],
      };
    }

    if (typeof event.capacity?.max !== 'undefined' && participants.length !== event.capacity.max) {
      return {
        text: t('eventDetails.settings.tabs.summary.notifications.participants.participants.unacceptableAmount'),
        notificationType: NOTIFICATION_TYPE[0],
      };
    }
  };

  return (
    <SectionSummary title={t(`eventDetails.settings.tabs.summary.${sectionId}.title`)} hidden={!isVisible}>
      <SectionBlock data-testid="participants-count" notification={getParticipantCountNotification()}>
        <SummaryLabel
          label={t('eventDetails.settings.tabs.participants.amountOfParticipants')}
          copyText={
            participantCount
              ? t('eventDetails.settings.tabs.participants.amountOfParticipants') + ': ' + participantCount.toString()
              : undefined
          }
        />
        {participantCount ?? <ReadonlyField variant="EMPTY" text={t('noData')} />}
      </SectionBlock>

      <SectionBlock data-testid="participants-count-note" notification={getParticipantCountNoteNotification()}>
        <SummaryLabel
          label={t('eventDetails.settings.tabs.participants.participantCountNote')}
          copyText={
            participantCountNote
              ? t('eventDetails.settings.tabs.participants.participantCountNote') + ': ' + participantCountNote
              : undefined
          }
        />
        {participantCountNote ?? <ReadonlyField variant="EMPTY" text={t('noData')} />}
      </SectionBlock>

      <SectionBlock
        data-testid="participants-registered-participants"
        notification={getRegisteredParticipantsNotification()}
      >
        <SummaryLabel label={t(`eventDetails.settings.tabs.participants.listLabel`)} />
        <ParticipantsTable enableEdit={false} />
      </SectionBlock>
    </SectionSummary>
  );
};

export default ParticipantsSummary;
