import { FunctionComponent, useState } from 'react';
import Section from '../Common/Section';
import TrainerAccommodationForm from './TrainerAccommodationForm';
import TrainerAccommodationReadonly from './TrainerAccommodationReadonly';
import { t } from 'i18next';

const TrainerAccommodation: FunctionComponent = () => {
  const [readonly, setReadonly] = useState(true);

  return (
    <Section
      setReadonly={setReadonly}
      readonly={readonly}
      tabId="trainerHotel"
      title={t('eventDetails.settings.tabs.trainerAccommodation.label')}
    >
      {readonly ? <TrainerAccommodationReadonly /> : <TrainerAccommodationForm />}
    </Section>
  );
};

export default TrainerAccommodation;
