import { Box, Button, CircularProgress } from '@mui/material';
import { FunctionComponent, useContext } from 'react';
import { useEventSettingsMutation } from '../../../queries/event-settings';
import JWTContext from '../../JWTContext';
import { useEventSettings, useEventSettingsDispatch } from '../../Context/EventSettingsContext';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { AlertContext } from '../../AlertSnackbar/AlertContext';
import { contactDataCompleted } from './ContactData/ContactDataForm';
import { IEventSettingsData } from '@shared/interfaces';

interface ISaveButtonParams {
  callBackFn: () => void;
  tabId?: keyof IEventSettingsData;
}

const SaveButton: FunctionComponent<ISaveButtonParams> = ({ callBackFn, tabId }) => {
  const { getJWT } = useContext(JWTContext);
  const { enqueueAlert } = useContext(AlertContext);
  const { eventSettings, dirty } = useEventSettings();
  const eventSettingsDispatch = useEventSettingsDispatch();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { mutate: updateEventSettings, isPending: isUpdating } = useEventSettingsMutation(getJWT, queryClient);

  if (!eventSettings || 'snapshotType' in eventSettings) return <></>;

  const { eventId, completenessCheck: _completenessCheck, status: _status, ...rest } = eventSettings;

  return (
    <Box mt={5}>
      <Button
        startIcon={isUpdating && <CircularProgress size={12} />}
        disabled={
          isUpdating ||
          Object.keys(dirty).length === 0 ||
          (tabId === 'contactData' && !contactDataCompleted(eventSettings.contactData))
        }
        onClick={() => {
          updateEventSettings(
            { eventId, data: rest },
            {
              onError: () => {
                enqueueAlert({
                  message: t('eventDetails.settings.alerts.updateError'),
                  type: 'error',
                });
              },
              onSuccess: () => {
                eventSettingsDispatch({ type: 'update-event-settings-original' });
                eventSettingsDispatch({ type: 'reset-dirty-state' });
                callBackFn();
                enqueueAlert({
                  message: t('eventDetails.settings.alerts.updateSuccess'),
                  type: 'success',
                });
              },
            }
          );
        }}
        variant="contained"
      >
        {t('save')}
      </Button>
    </Box>
  );
};

export default SaveButton;
