import { useQuery } from '@tanstack/react-query';
import { getApiService } from '../api/api-request';

export const eventQueryKeys = {
  list: ['events'] as const,
  event: (eventId: string) => [...eventQueryKeys.list, eventId] as const,
  eventData: (eventId: string) => ['event-data', eventId] as const,
};

export const useEvent = (eventId: string, getJWT: () => Promise<string | undefined | null>) => {
  return useQuery({
    queryKey: eventQueryKeys.event(eventId),
    queryFn: () => getApiService(getJWT).getEvent(eventId),
  });
};
