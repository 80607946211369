import { FunctionComponent, useContext, useState } from 'react';
import { Alert, Box, Button, LinearProgress, Stack, TextField, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import JWTContext from '../components/JWTContext';
import { getApiService } from '../api/api-request';
import { MICROFRONTEND_ID } from '../config';
import { useNavigate } from 'react-router-dom';

export const Events: FunctionComponent = () => {
  const [eventId, setEventId] = useState('');
  const [notFound, setNotFound] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { getJWT } = useContext(JWTContext);

  const handleSearch = async () => {
    setLoading(true);
    const resp = await getApiService(getJWT).getEvent(eventId);
    setLoading(false);
    if (resp) {
      navigate(`/${MICROFRONTEND_ID}/events/${eventId}`);
    } else {
      setNotFound(true);
    }
  };

  return (
    <Box mt={2} maxWidth={500}>
      <Typography variant="headingSm" mb={3}>
        {t('events.header')}
      </Typography>

      <Stack direction={'row'} spacing={2} mb={3} alignItems={'end'}>
        <TextField
          margin="dense"
          id="search"
          label={t('events.eventId')}
          size="small"
          value={eventId}
          onChange={(e) => {
            setEventId(e.target.value);
            setNotFound(false);
          }}
          fullWidth={true}
        />
        <Button variant="contained" disabled={!eventId} onClick={handleSearch}>
          {t('events.search')}
        </Button>
      </Stack>

      {loading && <LinearProgress />}
      {notFound && (
        <Alert variant="outlined" severity="error" sx={{ width: '800px' }}>
          <Trans
            i18nKey={'eventDetails.settings.tabs.feedback.eventNotFound'}
            components={{
              a: <a href="mailto: _AKA_Domaene_LOS@haufe-lexware.com"></a>,
              ol: <ol></ol>,
              li: <li></li>,
            }}
          />
        </Alert>
      )}
    </Box>
  );
};
