import { FunctionComponent, useState } from 'react';
import Section from '../Common/Section';
import ParticipantsForm from './ParticipantsForm';
import ParticipantsReadonly from './ParticipantsReadonly';
import { t } from 'i18next';

const Participants: FunctionComponent = () => {
  const [readonly, setReadonly] = useState(true);

  return (
    <Section
      setReadonly={setReadonly}
      readonly={readonly}
      tabId="participants"
      title={t('eventDetails.settings.tabs.participants.label')}
    >
      {readonly ? <ParticipantsReadonly /> : <ParticipantsForm />}
    </Section>
  );
};

export default Participants;
