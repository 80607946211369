import { FunctionComponent } from 'react';
import SectionSummary from '../Common/SectionSummary';
import useSectionVisibility from '../../../../hooks/useSectionVisibility';
import { useTranslation } from 'react-i18next';
import { IEventSettingsData } from '@shared/interfaces';
import { useEventSettings } from '../../../Context/EventSettingsContext';
import { ReadonlyField } from '../Common/ReadonlyField';
import SectionBlock from '../Common/SectionBlock';
import { SummaryLabel } from '../Common/SummaryLabel';

const FeedbackSummary: FunctionComponent = () => {
  const sectionId: keyof IEventSettingsData = 'feedback';
  const { isVisible } = useSectionVisibility(sectionId);
  const { t } = useTranslation();

  const { event, eventSettings } = useEventSettings();
  const { enabled, surveyType, recipientEmailAddresses } = eventSettings?.feedback ?? {};

  const defaultLabel = t(
    `eventDetails.settings.tabs.feedback.surveyType.DEFAULT.${!!event?.isOnline ? 'ONLINE' : 'ONSITE'}`
  );

  return (
    <SectionSummary title={t(`eventDetails.settings.tabs.summary.${sectionId}.title`)} hidden={!isVisible}>
      <SectionBlock>
        <SummaryLabel label={t('eventDetails.settings.tabs.feedback.system')} />
        {enabled ? (
          <ReadonlyField text={t('eventDetails.settings.tabs.feedback.haufeFeedbackSystem')} variant="TRUE" />
        ) : (
          <ReadonlyField text={t('eventDetails.settings.tabs.feedback.otherFeedbackSystem')} variant="FALSE" />
        )}
      </SectionBlock>

      {enabled && (
        <>
          {event?.businessUnit === 'IHC' && (
            <SectionBlock>
              <SummaryLabel label={t('eventDetails.settings.tabs.feedback.surveyTypeLabel')} />
              <ReadonlyField
                text={
                  surveyType === 'DEFAULT'
                    ? defaultLabel
                    : t('eventDetails.settings.tabs.feedback.surveyType.ASSESSMENT_CENTER')
                }
                variant="TEXT"
              />
            </SectionBlock>
          )}
          <SectionBlock>
            <SummaryLabel label={t('eventDetails.settings.tabs.feedback.recipientLabel')} />
            <ReadonlyField
              text={recipientEmailAddresses?.[0] || t('missingData')}
              error={!recipientEmailAddresses?.[0]}
              variant="TEXT"
            />
          </SectionBlock>
        </>
      )}
    </SectionSummary>
  );
};

export default FeedbackSummary;
