import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from '@mui/material';
import { IAddress } from '@shared/interfaces';
import { Dispatch, FunctionComponent, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';

const COUNTRIES = ['DE', 'AT', 'CH'];

interface IAddressProps {
  address?: Partial<IAddress>;
  onChange: (address: Partial<IAddress> | undefined) => void;
  openDialog: Dispatch<SetStateAction<boolean>>;
}
const reg = /^\d{4,5}$/;

const requiredFields: (keyof IAddress)[] = ['line1', 'street', 'postalCode', 'city', 'country'];

export const checkAdressCompleted = (address: Partial<IAddress> | undefined): boolean => {
  return (
    !!address && reg.test(address.postalCode ?? '') && requiredFields.every((item) => (address[item]?.length ?? 0) > 0)
  );
};

export const INITIAL_ADDRESS: IAddress = {
  line1: '',
  city: '',
  country: 'DE',
  postalCode: '',
  street: '',
};

const AddressFormDialog: FunctionComponent<IAddressProps> = (props) => {
  const { t } = useTranslation();

  const [invalidPlz, setInvalidPlz] = useState(!reg.test(props.address?.postalCode || ''));
  const [newCustomAdress, setNewCustomAdress] = useState(props.address ?? INITIAL_ADDRESS);

  const handleChange = () => {
    props.onChange(newCustomAdress);
    handleClose();
  };

  const handleChange1 = (key: string, value: string) => {
    setNewCustomAdress({
      ...newCustomAdress,
      [key]: value,
    });
  };

  const handleCountryChange = (event: SelectChangeEvent<string>) => {
    handleChange1('country', event.target.value);
  };

  const handlePlzChange = (key: string, value: string) => {
    setInvalidPlz(!reg.test(value));
    handleChange1(key, value);
  };

  const handleClose = (_event?: object, reason?: 'backdropClick' | 'escapeKeyDown') => {
    if (reason !== 'backdropClick') {
      props.openDialog(false);
    }
  };

  return (
    <Dialog open={true} fullWidth={true} onClose={handleClose} data-testid="address-form-dialog">
      <DialogTitle>{t('address.dialog.label')}</DialogTitle>
      <DialogContent>
        {
          <Stack>
            <TextField
              label={t('address.line1')}
              value={newCustomAdress?.line1 ?? ''}
              onChange={(e) => handleChange1('line1', e.target.value)}
              margin="normal"
              placeholder={t('address.line1')}
              error={newCustomAdress?.line1?.length === 0}
              required={requiredFields.includes('line1')}
            />

            <TextField
              label={t('address.line2')}
              value={newCustomAdress?.line2 ?? ''}
              onChange={(e) => handleChange1('line2', e.target.value)}
              placeholder={t('address.line2')}
              margin="normal"
              required={requiredFields.includes('line2')}
            />

            <TextField
              label={t('address.street')}
              value={newCustomAdress?.street ?? ''}
              onChange={(e) => handleChange1('street', e.target.value)}
              placeholder={t('address.street')}
              error={newCustomAdress?.street?.length === 0}
              margin="normal"
              required={requiredFields.includes('street')}
            />

            <TextField
              label={t('address.postalCode')}
              value={newCustomAdress?.postalCode ?? ''}
              onChange={(e) => handlePlzChange('postalCode', e.target.value)}
              placeholder={t('address.postalCode')}
              error={invalidPlz}
              margin="normal"
              required={requiredFields.includes('postalCode')}
            />
            <TextField
              label={t('address.city')}
              value={newCustomAdress?.city ?? ''}
              onChange={(e) => handleChange1('city', e.target.value)}
              placeholder={t('address.city')}
              error={newCustomAdress?.city?.length === 0}
              margin="normal"
              required={requiredFields.includes('city')}
            />

            <FormControl margin="normal">
              <InputLabel id="country-select-label">{t('address.country')}</InputLabel>
              <Select
                labelId="country-select-label"
                label={t('address.country')}
                value={newCustomAdress?.country ?? 'DE'}
                onChange={handleCountryChange}
                notched={true}
              >
                {COUNTRIES.map((countryCode) => (
                  <MenuItem
                    selected={countryCode === newCustomAdress?.country}
                    key={`country-select-item-${countryCode}`}
                    value={countryCode}
                  >
                    {t(`countries.${countryCode}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        }
      </DialogContent>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 10,
          top: 10,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogActions sx={{ mb: 2, mx: 2 }}>
        <Button variant="contained" autoFocus disabled={!checkAdressCompleted(newCustomAdress)} onClick={handleChange}>
          {t('address.dialog.save')}
        </Button>
        <Button variant="outlined" onClick={handleClose}>
          {t('address.dialog.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddressFormDialog;
