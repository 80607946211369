import ReactDOM from 'react-dom/client';
import { IMicroFrontendInstance } from '@hmg-aka-lu/cockpit-frontend/dist-types/exports';

// import {
//   createBrowserHistory,
//   History,
// } from 'history';
import './i18n';

import '@packages/lighthouse-theme/src/FontFace.css';
import MicrofrontendThemeProvider from './theme/MicrofrontendThemeProvider';
import mergedConfig, { MICROFRONTEND_ID } from './config';
import JWTContext from './components/JWTContext';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AlertSnackBar } from './components/AlertSnackbar/AlertSnackbar';
import { Events } from './pages/Events';
import { EventDetails } from './pages/EventDetails';
import { EventRedirect } from './router/EventRedirect';
declare global {
  interface Window {
    [MICROFRONTEND_ID]: IMicroFrontendInstance;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const microFrontendInstance: IMicroFrontendInstance = (containerId = 'root', _history: any, microfrontendMeta) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        // staleTime: 300000,
      },
    },
  });

  const root = ReactDOM.createRoot(document.getElementById(containerId) as HTMLElement);

  return {
    render: () => {
      root.render(
        <MicrofrontendThemeProvider>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <JWTContext.Provider
              value={{
                getJWT: microfrontendMeta.getJWT,
                hasRequiredGroup: microfrontendMeta.hasRequiredGroup,
                authenticatedContext: { groups: microfrontendMeta.authenticatedContext.groups ?? [] },
              }}
            >
              <BrowserRouter>
                <Routes>
                  <Route path={`/${MICROFRONTEND_ID}/events`} element={<Events />} />

                  <Route
                    path={`/${MICROFRONTEND_ID}/events/:eventId/overview`}
                    element={<EventDetails view="overview" />}
                  />
                  <Route
                    path={`/${MICROFRONTEND_ID}/events/:eventId/configuration`}
                    element={<EventDetails view="configuration" />}
                  />
                  <Route path={`/${MICROFRONTEND_ID}/events/:eventId`} element={<EventRedirect />} />
                  <Route path="*" element={<Navigate replace to={`/${MICROFRONTEND_ID}/events`} />} />
                </Routes>
              </BrowserRouter>
              <AlertSnackBar />
            </JWTContext.Provider>
          </QueryClientProvider>
        </MicrofrontendThemeProvider>
      );
    },
  };
};

window.organizations = microFrontendInstance;

if (mergedConfig.STANDALONE) {
  const microFrontend = microFrontendInstance(
    'root',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    undefined,
    {
      getJWT: async () => {
        return '';
      },
      hasCockpitAccess: () => true,
      hasRequiredGroup: () => true,
      authenticatedContext: { groups: [] },
      relativeUrl: '12312',
    }
  );
  microFrontend.render();
}
