import { FunctionComponent } from 'react';
import { Checkbox, FormControl, FormControlLabel, MenuItem, Select } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { SurveyType } from '@shared/interfaces';
import { Stack } from '@mui/system';
import { useEventSettings, useEventSettingsDispatch } from '../../../Context/EventSettingsContext';
import { DefaultLabel } from '../Common/DefaultLabel';
import EmailRecipient from '../Common/EmailRecipient';
import SectionBlock from '../Common/SectionBlock';

const FeedbackForm: FunctionComponent = () => {
  const { event, eventSettings } = useEventSettings();
  const eventSettingsDispatch = useEventSettingsDispatch();

  const { t } = useTranslation();

  if (!eventSettings || !event) return <></>;

  const { enabled, surveyType } = eventSettings.feedback ?? {};

  const defaultLabel = t(
    `eventDetails.settings.tabs.feedback.surveyType.DEFAULT.${!!event.isOnline ? 'ONLINE' : 'ONSITE'}`
  );

  return (
    <Stack>
      <SectionBlock>
        <DefaultLabel
          alertText={t('eventDetails.settings.tabs.feedback.feedbackHint')}
          alertTitle={t('generalHintTitle')}
          label={t('eventDetails.settings.tabs.feedback.hintLabel')}
          alertSeverity="info"
        />
        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={!!enabled}
                onChange={(_event, checked: boolean) =>
                  eventSettingsDispatch({
                    type: 'update',
                    payload: {
                      feedback: {
                        enabled: checked,
                        surveyType: checked ? 'DEFAULT' : undefined,
                      },
                    },
                  })
                }
              />
            }
            label={t('eventDetails.settings.tabs.feedback.feedbackSystemCheckbox')}
          />
        </FormControl>
      </SectionBlock>

      {enabled && (
        <>
          <SectionBlock>
            {event?.businessUnit === 'IHC' && (
              <>
                <DefaultLabel
                  alertText={t(`eventDetails.settings.tabs.feedback.surveyTypeFormHint.IHC`)}
                  alertTitle={t('generalHintTitle')}
                  label={t('eventDetails.settings.tabs.feedback.surveyTypeLabel')}
                  alertSeverity="info"
                />
                <FormControl margin="normal" fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={surveyType}
                    onChange={(event) =>
                      eventSettingsDispatch({
                        type: 'update',
                        payload: {
                          feedback: { surveyType: event.target.value as SurveyType },
                        },
                      })
                    }
                  >
                    <MenuItem value={'DEFAULT'}>{defaultLabel}</MenuItem>
                    <MenuItem value={'ASSESSMENT_CENTER'}>
                      {t(`eventDetails.settings.tabs.feedback.surveyType.ASSESSMENT_CENTER`)}
                    </MenuItem>
                  </Select>
                </FormControl>
              </>
            )}
          </SectionBlock>

          <SectionBlock>
            <EmailRecipient
              hintText={<Trans i18nKey={'eventDetails.settings.tabs.feedback.recipientHint'} />}
              title={t('eventDetails.settings.tabs.feedback.recipientLabel')}
              section="feedback"
              requiredInput
            />
          </SectionBlock>
        </>
      )}
    </Stack>
  );
};

export default FeedbackForm;
