import { ADDRESS_SECTIONS, IAddress, IEventSettings, IEventSettingsSnapshot } from '@shared/interfaces';

const MAX_ITERATIONS = 10;

export const resolveAddress = (
  eventSettings: IEventSettings | IEventSettingsSnapshot,
  section: ADDRESS_SECTIONS,
  iteration = 0
): Partial<IAddress> | undefined => {
  if (iteration >= MAX_ITERATIONS) {
    throw new Error(`Maximum address nesting level (${MAX_ITERATIONS}) reached`);
  }

  switch (section) {
    case 'contactData': {
      return eventSettings?.contactData?.customerAddress;
    }

    case 'location': {
      const { eventLocation, eventLocationRef } = eventSettings?.location ?? {};

      if (typeof eventLocationRef === 'undefined') {
        return undefined;
      }

      if (eventLocationRef === 'location') {
        return eventLocation;
      }

      return resolveAddress(eventSettings, eventLocationRef, iteration + 1);
    }

    case 'seminarDocuments': {
      const { recipientAddress, recipientAddressRef } = eventSettings?.seminarDocuments ?? {};

      if (typeof recipientAddressRef === 'undefined') {
        return undefined;
      }

      if (recipientAddressRef === 'seminarDocuments') {
        return recipientAddress;
      }

      return resolveAddress(eventSettings, recipientAddressRef, iteration + 1);
    }
  }
};
