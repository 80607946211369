import { ChangeEvent, FunctionComponent } from 'react';
import { Checkbox, FormControl, FormControlLabel } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useEventSettings, useEventSettingsDispatch } from '../../../Context/EventSettingsContext';
import { DefaultLabel } from '../Common/DefaultLabel';
import EmailRecipient from '../Common/EmailRecipient';
import SectionBlock from '../Common/SectionBlock';

const ConfirmationOfParticipationForm: FunctionComponent<unknown> = () => {
  const { eventSettings } = useEventSettings();
  const eventSettingsDispatch = useEventSettingsDispatch();

  const { t } = useTranslation();

  if (!eventSettings) return <></>;

  const { enabled } = eventSettings.confirmationOfParticipation ?? {};

  const handleChangeEnable = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    eventSettingsDispatch({
      type: 'update',
      payload: {
        confirmationOfParticipation: {
          enabled: checked,
        },
      },
    });
  };

  return (
    <>
      <SectionBlock>
        <DefaultLabel
          alertTitle={t('generalHintTitle')}
          label={t('eventDetails.settings.tabs.confirmationOfParticipation.certificationLabel')}
          alertText={t('eventDetails.settings.tabs.confirmationOfParticipation.certificateHint')}
        />
        <FormControl>
          <FormControlLabel
            control={<Checkbox checked={enabled ?? true} onChange={handleChangeEnable} />}
            label={t('eventDetails.settings.tabs.confirmationOfParticipation.certificationsCheckbox')}
          />
        </FormControl>
      </SectionBlock>

      {enabled && (
        <SectionBlock>
          <EmailRecipient
            hintText={<Trans i18nKey={'eventDetails.settings.tabs.confirmationOfParticipation.recipientAddressHint'} />}
            title={t('eventDetails.settings.tabs.confirmationOfParticipation.recipientAddressLabel')}
            section="confirmationOfParticipation"
            requiredInput
          />
        </SectionBlock>
      )}
    </>
  );
};

export default ConfirmationOfParticipationForm;
