import { FunctionComponent, useContext } from 'react';

import { useUsersList } from '../../../../queries/users';
import JWTContext from '../../../JWTContext';
import { Avatar, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { useEventSettings } from '../../../Context/EventSettingsContext';
import { ReadonlyField } from './ReadonlyField';
import { useTranslation } from 'react-i18next';

interface IContactPersonsProps {}

const ContactPersonsReadOnly: FunctionComponent<IContactPersonsProps> = () => {
  const { getJWT } = useContext(JWTContext);
  const { t } = useTranslation();

  const { eventSettings, debitorId } = useEventSettings();
  const { data: users, isLoading } = useUsersList(getJWT, debitorId);

  const contactPersons = eventSettings?.contactData?.contactPersonsCustomer ?? [];

  return (
    <>
      {contactPersons.length > 0 ? (
        contactPersons.map((contactPerson) => {
          const user = users.find((item) => item.id === contactPerson);

          return (
            !isLoading && (
              <ListItem key={`selected-recipient-${contactPerson}`}>
                <ListItemAvatar>
                  <Avatar>{user ? `${user.firstName?.[0]}${user.lastName?.[0]}` : ''}</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    user
                      ? `${user.firstName} ${user.lastName}`
                      : t('eventDetails.settings.tabs.contactData.deletedUser')
                  }
                  secondary={user ? user.emailAddress : ''}
                />
              </ListItem>
            )
          );
        })
      ) : (
        <ReadonlyField text={t('missingData')} variant="TEXT" error={true} />
      )}
    </>
  );
};

export default ContactPersonsReadOnly;
