import {
  IActivityLogApiResponse,
  ICreateParticipantRequestBody,
  ICreateUserRequestBody,
  IEventListItemResponse,
  IEventSettingsResponse,
  IEventSettingsSnapshot,
  IInternalContactPersons,
  IParticipant,
  IUpdateEventSettingsRequestBody,
  IUserResponseBody,
} from '@shared/interfaces';
import mergedConfig from '../config';

export interface IEventResponseBody {
  geschaeftsbereich: string;
  title: string;
}

export const getApiService = (getJWT: () => Promise<string | undefined | null>) => {
  const apiRequest = async (
    path: string,
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
    body?: BodyInit,
    params?: Record<string, string>
  ) => {
    const jwt = await getJWT();
    if (!jwt) {
      throw new Error('Could not get JWT');
    }

    const result = await fetch(`${mergedConfig.API_URL}/${path}?${new URLSearchParams(params)}`, {
      method,
      body,
      headers: {
        'Content-Type': 'application/json',
        authorization: jwt ?? '',
      },
    });

    if (result.status >= 400) {
      const data = await result.json();
      throw new Error(data.message);
    }

    return await result.text();
  };

  const getEvent = async (eventId: string) => {
    const result = await apiRequest(`events/${eventId}`, 'GET');

    return JSON.parse(result) as unknown as IEventListItemResponse;
  };

  const getEventSettings = async (eventId: string) => {
    const result = await apiRequest(`events/${eventId}/settings`, 'GET');

    return JSON.parse(result) as unknown as IEventSettingsResponse;
  };

  const getUsers = async (debitorId?: string) => {
    if (typeof debitorId === 'undefined') {
      throw new Error('Invalid debitor id');
    }

    const result = await apiRequest(`users/${debitorId}`, 'GET');

    return JSON.parse(result) as unknown as IUserResponseBody[];
  };

  const getInternalContact = async (email: string) => {
    const result = await apiRequest(`internal-contacts/${email}`, 'GET');

    return JSON.parse(result) as unknown as IInternalContactPersons;
  };

  const getEventSettingsChanges = async (eventId: string) => {
    const result = await apiRequest(`events/${eventId}/settings-changes`, 'GET');

    return JSON.parse(result) as unknown as {
      [key: string]: {
        date: string;
        value: string | boolean | null | number;
      };
    };
  };

  const getEventSettingsSnapshots = async (eventId: string) => {
    const result = await apiRequest(`events/${eventId}/settings/snapshots`, 'GET');

    return JSON.parse(result) as IEventSettingsSnapshot[];
  };

  const updateEventSettings = async (eventId: string, data: IUpdateEventSettingsRequestBody) => {
    const result = await apiRequest(`events/${eventId}/settings`, 'PATCH', JSON.stringify(data));

    return JSON.parse(result) as unknown as IEventSettingsResponse;
  };

  const createUser = async (data: ICreateUserRequestBody) => {
    const result = await apiRequest('users', 'POST', JSON.stringify(data));

    return JSON.parse(result) as unknown as IUserResponseBody;
  };

  const getParticipants = async (eventId?: string): Promise<IParticipant[]> => {
    if (typeof eventId === 'undefined') {
      throw new Error('Invalid event id');
    }
    const result = await apiRequest(`events/${eventId}/participants`, 'GET');

    return JSON.parse(result) as unknown as IParticipant[];
  };

  const createParticipant = async (eventId: string, participant: ICreateParticipantRequestBody) => {
    const result = await apiRequest(`events/${eventId}/participants`, 'POST', JSON.stringify(participant));

    return JSON.parse(result) as unknown as IParticipant[];
  };

  const deleteParticipant = async (eventId: string, participantId: string) => {
    await apiRequest(`events/${eventId}/participants/${participantId}`, 'DELETE');
  };

  const updateParticipant = async (
    eventId: string,
    participantId: string,
    participant: ICreateParticipantRequestBody
  ) => {
    const result = await apiRequest(
      `events/${eventId}/participants/${participantId}`,
      'PUT',
      JSON.stringify(participant)
    );

    return JSON.parse(result) as unknown as IParticipant[];
  };

  const getActivityLogs = async (eventId: string) => {
    const result = await apiRequest(`events/${eventId}/activity-logs`, 'GET');

    return JSON.parse(result) as unknown as IActivityLogApiResponse[];
  };

  return {
    createUser,
    getActivityLogs,
    getEvent,
    getEventSettings,
    getEventSettingsSnapshots,
    getEventSettingsChanges,
    getUsers,
    getParticipants,
    createParticipant,
    updateParticipant,
    deleteParticipant,
    updateEventSettings,
    getInternalContact,
  };
};
