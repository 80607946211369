import { Box, Grid, Paper, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { ReadonlyField } from './ReadonlyField';
import { useTranslation } from 'react-i18next';
import { ColorPalette } from '@hmg-aka-lu/elevate-theme';

interface ISectionProps {
  children: React.ReactNode;
  title: string;
  hidden?: boolean;
}

const SectionSummary: FunctionComponent<ISectionProps> = ({ children, title, hidden = false }) => {
  const { t } = useTranslation();

  return (
    <Box component={Paper} p={3} mb={3} bgcolor={ColorPalette.surface.elevated} elevation={0}>
      <Typography variant="headingSm" mb={2}>
        <Grid container alignItems={'center'}>
          <Grid item>{title}</Grid>
          <Grid item>
            {hidden && (
              <Box mt={1} ml={1}>
                <ReadonlyField variant="HIDDEN" text={t('eventDetails.settings.sectionSwitch.readonlyDialogTitle')} />
              </Box>
            )}
          </Grid>
        </Grid>
      </Typography>
      {children}
    </Box>
  );
};

export default SectionSummary;
