import { Sections } from '@shared/interfaces';
import { useEventSettings } from '../components/Context/EventSettingsContext';

const useSectionVisibility = (section: Sections) => {
  const { eventSettings } = useEventSettings();

  return {
    isVisible: !eventSettings?.hiddenSections.includes(section),
  };
};

export default useSectionVisibility;
