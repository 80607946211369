import { FunctionComponent } from 'react';
import { Box } from '@mui/material';
import { format } from 'date-fns';
import { IAblauf } from '@shared/interfaces';
import { ReadonlyField } from '../Common/ReadonlyField';
import { Stack } from '@mui/system';

interface IEventTimeProps {
  ablauf: IAblauf[];
}

const EventTime: FunctionComponent<IEventTimeProps> = ({ ablauf }) => {
  return (
    <Stack spacing={1.5} mt={0.5}>
      {ablauf.map((date) => {
        const day = format(new Date(date.startTime), 'dd.MM.yyyy');
        const startTime = format(new Date(date.startTime), 'HH:mm');
        const endTime = format(new Date(date.endTime), 'HH:mm');
        return (
          <Box key={`${date.startTime}-${date.endTime}`}>
            <ReadonlyField text={day} variant="DATE" />
            <ReadonlyField text={`${startTime} - ${endTime}`} variant="TIME" />
          </Box>
        );
      })}
    </Stack>
  );
};

export default EventTime;
