import { FunctionComponent, useState } from 'react';
import ContactDataReadonly from './ContactDataReadonly';
import ContactDataForm from './ContactDataForm';
import Section from '../Common/Section';
import { t } from 'i18next';
interface IContactDataProps {
  readonlyMode: boolean;
}

const ContactData: FunctionComponent<IContactDataProps> = ({ readonlyMode }) => {
  const [readonly, setReadonly] = useState(readonlyMode);

  return (
    <Section
      setReadonly={setReadonly}
      readonly={readonly}
      tabId="contactData"
      title={t('eventDetails.settings.tabs.contactData.label')}
    >
      {readonly ? <ContactDataReadonly /> : <ContactDataForm />}
    </Section>
  );
};

export default ContactData;
