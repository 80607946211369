import { QueryClient, useMutation, useQuery } from '@tanstack/react-query';
import { getApiService } from '../api/api-request';
import { IUpdateEventSettingsRequestBody } from '@shared/interfaces';

const ROOT_KEY = 'event-settings';

interface IUpdateEventSettingsMutationParams {
  eventId: string;
  data: IUpdateEventSettingsRequestBody;
}

export const eventSettingsQueryKeys = {
  event: (eventId: string) => [ROOT_KEY, eventId] as const,
  changes: (eventId: string) => [...eventSettingsQueryKeys.event(eventId), 'changes'],
};

export const useEventSettings = (eventId: string, getJWT: () => Promise<string | undefined | null>) => {
  return useQuery({
    queryKey: eventSettingsQueryKeys.event(eventId),
    queryFn: () => getApiService(getJWT).getEventSettings(eventId),
  });
};

export const useEventSettingsChanges = (eventId: string, getJWT: () => Promise<string | undefined | null>) => {
  return useQuery({
    queryKey: eventSettingsQueryKeys.changes(eventId),
    queryFn: () => getApiService(getJWT).getEventSettingsChanges(eventId),
  });
};

export const useEventSettingsMutation = (
  getJWT: () => Promise<string | undefined | null>,
  queryClient: QueryClient
) => {
  return useMutation({
    mutationFn: (params: IUpdateEventSettingsMutationParams) =>
      getApiService(getJWT).updateEventSettings(params.eventId, params.data),
    onSettled: (data) => {
      queryClient.invalidateQueries({ queryKey: eventSettingsQueryKeys.event(data?.eventId || '') });
    },
  });
};
