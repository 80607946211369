import { FunctionComponent, useContext, useState } from 'react';
import { Button, LinearProgress, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { participantsQueryKeys, useCreateParticipant } from '../../../../queries/participants';
import { useQueryClient } from '@tanstack/react-query';
import { IParticipantCsvRow } from './parseCsv';
import { useEventSettings } from '../../../Context/EventSettingsContext';
import JWTContext from '../../../JWTContext';
import { ColorPalette } from '@hmg-aka-lu/elevate-theme';

interface ICsvImportPreviewProps {
  onImportFinished: () => void;
  participantCsvRows: IParticipantCsvRow[];
}

const CsvImportPreview: FunctionComponent<ICsvImportPreviewProps> = ({ onImportFinished, participantCsvRows }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { eventSettings } = useEventSettings();
  const { getJWT } = useContext(JWTContext);

  const { mutateAsync: createParticipant } = useCreateParticipant(getJWT, queryClient, eventSettings?.eventId, false);

  const showInfoColumn = participantCsvRows.some(
    (participantCsvRow) => participantCsvRow.errors.length > 0 || participantCsvRow.exists
  );
  const hasErrors = participantCsvRows.some((participantCsvRow) => participantCsvRow.errors.length > 0);

  const [importing, setImporting] = useState(false);

  const handleImport = async () => {
    if (!eventSettings?.eventId) {
      return;
    }
    setImporting(true);
    for (const participantCsvRow of participantCsvRows) {
      if (participantCsvRow.errors.length === 0) {
        if (!participantCsvRow.exists && !participantCsvRow.id) {
          await createParticipant({
            eventId: eventSettings?.eventId,
            participant: participantCsvRow.data,
          });
        }
      }
    }
    setImporting(false);
    queryClient.invalidateQueries({ queryKey: participantsQueryKeys.eventParticipants(eventSettings?.eventId) });
    onImportFinished();
  };

  const getInfoColor = (participant: IParticipantCsvRow) => {
    if (participant.errors.length > 0 || participant.exists) {
      return ColorPalette.feedback.red;
    }
    return ColorPalette.feedback.green;
  };

  const getCountOfValidParticipant = () => {
    return participantCsvRows.filter((row) => !row.exists).length;
  };

  return (
    <>
      {participantCsvRows.filter((item) => {
        return item.errors.length > 0;
      }).length > 0 && (
        <Typography color={ColorPalette.feedback.red}>
          {t('eventDetails.settings.tabs.participants.csvImport.error')}
        </Typography>
      )}

      <Table sx={{ my: 3 }}>
        <TableHead>
          <TableRow>
            <TableCell>{t('eventDetails.settings.tabs.participants.table.title')}</TableCell>
            <TableCell>{t('eventDetails.settings.tabs.participants.table.firstName')}</TableCell>
            <TableCell>{t('eventDetails.settings.tabs.participants.table.lastName')}</TableCell>
            <TableCell>{t('eventDetails.settings.tabs.participants.table.email')}</TableCell>
            {showInfoColumn && (
              <TableCell align="right">{t('eventDetails.settings.tabs.participants.importPreview.info')}</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {participantCsvRows.map((participantCsvRow) => {
            return (
              <TableRow tabIndex={-1} key={`row-${participantCsvRow.data.lastName}-${participantCsvRow.data.email}`}>
                <TableCell>{participantCsvRow.data.title}</TableCell>
                <TableCell>{participantCsvRow.data.firstName}</TableCell>
                <TableCell>{participantCsvRow.data.lastName}</TableCell>
                <TableCell>{participantCsvRow.data.email}</TableCell>
                {showInfoColumn && (
                  <TableCell align="right" sx={{ color: getInfoColor(participantCsvRow) }}>
                    {participantCsvRow.exists &&
                      t('eventDetails.settings.tabs.participants.importPreview.participantExists')}
                    {participantCsvRow.errors
                      .map((error) => t(`eventDetails.settings.tabs.participants.importPreview.errors.${error}`))
                      .join(', ')}
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <Button
        variant="contained"
        disabled={hasErrors || importing || getCountOfValidParticipant() === 0}
        onClick={() => handleImport()}
      >
        {t('eventDetails.settings.tabs.participants.csvImport.startImport', {
          participantCount: getCountOfValidParticipant(),
        })}
      </Button>
      {importing && <LinearProgress sx={{ mt: 3 }} />}
    </>
  );
};

export default CsvImportPreview;
