import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from '@mui/material';
import { IAddress } from '@shared/interfaces';
import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';

const COUNTRIES = ['DE', 'AT', 'CH'];

interface IAddressProps {
  address?: Partial<IAddress>;
  onChange: (address: Partial<IAddress> | undefined) => void;
  isCustomAddress: boolean;
}

const requiredFields: (keyof IAddress)[] = ['line1', 'street', 'postalCode', 'city', 'country'];

export const checkAdressCompleted = (address: Partial<IAddress> | undefined): boolean => {
  return !!address && requiredFields.every((item) => (address[item]?.length ?? 0) > 0);
};

export const INITIAL_ADDRESS: IAddress = {
  line1: '',
  city: '',
  country: 'DE',
  postalCode: '',
  street: '',
};

const AddressForm: FunctionComponent<IAddressProps> = (props) => {
  const { t } = useTranslation();

  const reg = /^\d{4,5}$/;

  const [invalidPlz, setInvalidPlz] = useState(!reg.test(props.address?.postalCode || ''));

  const handleChange = (key: string, value: string) => {
    props.onChange({
      ...props.address,
      [key]: value,
    });
  };

  const handleCountryChange = (event: SelectChangeEvent<string>) => {
    handleChange('country', event.target.value);
  };

  const handlePlzChange = (key: string, value: string) => {
    setInvalidPlz(!reg.test(value));
    handleChange(key, value);
  };

  return (
    <Stack>
      <TextField
        label={t('address.line1')}
        value={props.address?.line1 ?? ''}
        onChange={(e) => handleChange('line1', e.target.value)}
        margin="normal"
        placeholder={t('address.line1')}
        error={props.address?.line1?.length === 0}
        required={requiredFields.includes('line1')}
      />

      <TextField
        label={t('address.line2')}
        value={props.address?.line2 ?? ''}
        onChange={(e) => handleChange('line2', e.target.value)}
        placeholder={t('address.line2')}
        margin="normal"
        required={requiredFields.includes('line2')}
      />

      <TextField
        label={t('address.street')}
        value={props.address?.street ?? ''}
        onChange={(e) => handleChange('street', e.target.value)}
        placeholder={t('address.street')}
        error={props.address?.street?.length === 0}
        margin="normal"
        required={requiredFields.includes('street')}
      />

      <TextField
        label={t('address.postalCode')}
        value={props.address?.postalCode ?? ''}
        onChange={(e) => handlePlzChange('postalCode', e.target.value)}
        placeholder={t('address.postalCode')}
        error={invalidPlz}
        margin="normal"
        required={requiredFields.includes('postalCode')}
      />
      <TextField
        label={t('address.city')}
        value={props.address?.city ?? ''}
        onChange={(e) => handleChange('city', e.target.value)}
        placeholder={t('address.city')}
        error={props.address?.city?.length === 0}
        margin="normal"
        required={requiredFields.includes('city')}
      />

      <FormControl margin="normal">
        <InputLabel id="country-select-label">{t('address.country')}</InputLabel>
        <Select
          labelId="country-select-label"
          label={t('address.country')}
          value={props.address?.country ?? 'DE'}
          onChange={handleCountryChange}
          notched={true}
        >
          {COUNTRIES.map((countryCode) => (
            <MenuItem
              selected={countryCode === props.address?.country}
              key={`country-select-item-${countryCode}`}
              value={countryCode}
            >
              {t(`countries.${countryCode}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {props.isCustomAddress && (
        <Box mt={2}>
          <Button variant="outlined" onClick={() => props.onChange(undefined)}>
            {t('address.reset')}
          </Button>
        </Box>
      )}
    </Stack>
  );
};

export default AddressForm;
