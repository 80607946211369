import { FunctionComponent, HTMLAttributes, ReactNode, useContext } from 'react';
import { Autocomplete, Avatar, FormControl, ListItem, ListItemAvatar, ListItemText, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IUserResponseBody } from '@shared/interfaces';
import {
  EmailRecipientSection,
  useEventSettings,
  useEventSettingsDispatch,
} from '../../../Context/EventSettingsContext';
import { useUsersList } from '../../../../queries/users';
import JWTContext from '../../../JWTContext';
import { EMAIL_REGEX } from '../../../../config';
import { DefaultLabel } from './DefaultLabel';

interface IEmailRecipientsProps {
  section: EmailRecipientSection;
  title?: string;
  hintText: string | ReactNode;
  requiredInput?: boolean;
}

interface IUserOptionType {
  id?: string;
  inputValue?: string;
  emailAddress: string;
  firstName?: string;
  lastName?: string;
}

interface IAutoCompleteListItemProps {
  props: HTMLAttributes<HTMLLIElement>;
  user: IUserOptionType;
}

export const checkMail = (email: string) => {
  return email.match(EMAIL_REGEX);
};

const EmailRecipient: FunctionComponent<IEmailRecipientsProps> = ({
  section,
  title,
  hintText,
  requiredInput = false,
}) => {
  const { eventSettings } = useEventSettings();
  const eventSettingsDispatch = useEventSettingsDispatch();
  const { t } = useTranslation();
  const { getJWT } = useContext(JWTContext);

  if (!eventSettings) return <></>;

  const { debitorId } = useEventSettings();
  const { data: users } = useUsersList(getJWT, debitorId);

  const { recipientEmailAddresses } = eventSettings[section] ?? {};

  let contactPersonsCustomer: IUserResponseBody | undefined;

  if (
    eventSettings.contactData?.contactPersonsCustomer &&
    eventSettings.contactData?.contactPersonsCustomer.length > 0
  ) {
    const contactPersonsCustomerId = eventSettings.contactData.contactPersonsCustomer[0];
    contactPersonsCustomer = users.find((user) => user.id === contactPersonsCustomerId);
  }

  const AutoCompleteListItem: FunctionComponent<IAutoCompleteListItemProps> = ({ props, user }) => {
    return !user.inputValue ? (
      <ListItem {...props}>
        <ListItemAvatar>
          <Avatar>
            {user.firstName?.[0]}
            {user.lastName?.[0]}
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={`${user.firstName} ${user.lastName}`} secondary={user.emailAddress} />
      </ListItem>
    ) : (
      <></>
    );
  };

  const updateEmailContext = (value: string) => {
    eventSettingsDispatch({
      type: 'set-single-email-recipient',
      payload: { value, section },
    });
  };

  return (
    <>
      <DefaultLabel
        alertTitle={t('generalHintTitle')}
        label={title ?? t('eventDetails.common.emailRecipients.recipient')}
        alertText={hintText}
      />

      <Autocomplete
        freeSolo
        disableClearable
        fullWidth
        options={contactPersonsCustomer ? [contactPersonsCustomer] : []}
        value={recipientEmailAddresses?.[0] ?? ''}
        onChange={(_event, value) => {
          if (typeof value === 'string') {
            updateEmailContext(value);
          } else {
            updateEmailContext(value.emailAddress);
          }
        }}
        renderOption={(props, option) => (
          <AutoCompleteListItem key={`select-item-${option.id}`} props={props} user={option} />
        )}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          return `${option.emailAddress}`;
        }}
        renderInput={(params) => (
          <FormControl margin="normal" fullWidth>
            <TextField
              {...params}
              label={`${t('eventDetails.common.emailRecipients.recipientLabel')}`}
              placeholder={`${t('eventDetails.common.emailRecipients.recipientLabel')}`}
              value={recipientEmailAddresses?.[0] ?? ''}
              error={!checkMail(recipientEmailAddresses?.[0] ?? '')}
              required={requiredInput}
              fullWidth
              onChange={(e) => {
                updateEmailContext(e.target.value);
              }}
            />
          </FormControl>
        )}
      />
    </>
  );
};

export default EmailRecipient;
