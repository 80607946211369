import { FunctionComponent, HTMLAttributes, useContext, useState } from 'react';

import { useCreateUser, useUsersList } from '../../../../queries/users';
import JWTContext from '../../../JWTContext';
import {
  Alert,
  Autocomplete,
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  LinearProgress,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Tooltip,
  createFilterOptions,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useQueryClient } from '@tanstack/react-query';
import { useEventSettings, useEventSettingsDispatch } from '../../../Context/EventSettingsContext';
import { useTranslation } from 'react-i18next';
import { checkMail } from './EmailRecipient';

interface IContactPersonsProps {
  deleteActionVisibility?: boolean;
}

const filter = createFilterOptions<IUserOptionType>();

interface IUserOptionType {
  id?: string;
  inputValue?: string;
  emailAddress: string;
  firstName?: string;
  lastName?: string;
}

interface IAutoCompleteListItemProps {
  props: HTMLAttributes<HTMLLIElement>;
  user: IUserOptionType;
}

const AutoCompleteListItem: FunctionComponent<IAutoCompleteListItemProps> = ({ props, user }) => {
  const { t } = useTranslation();

  return !user.inputValue ? (
    <ListItem {...props}>
      <ListItemAvatar>
        <Avatar>
          {user.firstName?.[0]}
          {user.lastName?.[0]}
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={`${user.firstName} ${user.lastName}`} secondary={user.emailAddress} />
    </ListItem>
  ) : (
    <li {...props}>
      {t('eventDetails.createUser')} {user.inputValue}
    </li>
  );
};

const ContactPersons: FunctionComponent<IContactPersonsProps> = ({ deleteActionVisibility = true }) => {
  const { getJWT } = useContext(JWTContext);
  const queryClient = useQueryClient();

  const { eventSettings, debitorId } = useEventSettings();

  if (!eventSettings) {
    return <></>;
  }

  const { data: users } = useUsersList(getJWT, debitorId);

  const { mutate: createUser, isPending: creatingUser, error: createUserError } = useCreateUser(getJWT, queryClient);

  const { t } = useTranslation();

  const userOptions = users as IUserOptionType[];

  const dispatch = useEventSettingsDispatch();

  const [value, setValue] = useState<IUserOptionType | null>(null);
  const [createUserDialogOpen, setCreateUserDialogOpen] = useState(false);
  const [createUserDialogValue, setCreateUserDialogValue] = useState({
    emailAddress: '',
    firstName: '',
    lastName: '',
  });

  const contactPersons = eventSettings.contactData?.contactPersonsCustomer ?? [];

  const handleCloseCreateUserDialog = () => {
    setCreateUserDialogValue({
      emailAddress: '',
      firstName: '',
      lastName: '',
    });
    setCreateUserDialogOpen(false);
  };

  const handleCreateUser = () => {
    createUser(
      { ...createUserDialogValue, debitorId },
      {
        onSuccess: (data) => {
          dispatch({ type: 'add-contact-person', payload: data.id });
          updateEmailForOtherParts(createUserDialogValue.emailAddress);
          handleCloseCreateUserDialog();
        },
      }
    );
  };

  const updateEmailForOtherParts = (newEmail: string) => {
    const eMailFeedback = eventSettings.feedback?.recipientEmailAddresses?.[0];
    const eMailTb = eventSettings.confirmationOfParticipation?.recipientEmailAddresses?.[0];
    const eMailSu = eventSettings.seminarDocuments?.recipientEmailAddresses?.[0];
    if (!eMailFeedback || eMailFeedback === '') {
      dispatch({
        type: 'set-single-email-recipient',
        payload: { value: newEmail, section: 'feedback' },
      });
    }
    if (!eMailTb || eMailTb === '') {
      dispatch({
        type: 'set-single-email-recipient',
        payload: { value: newEmail, section: 'confirmationOfParticipation' },
      });
    }
    if (!eMailSu || eMailSu === '') {
      dispatch({
        type: 'set-single-email-recipient',
        payload: { value: newEmail, section: 'seminarDocuments' },
      });
    }
  };

  const optionsFilter = (item: IUserOptionType) => {
    return !contactPersons.includes(item.id ?? '');
  };

  return (
    <>
      {contactPersons?.map((contactPerson) => {
        const user = users.find((item) => item.id === contactPerson);

        return (
          <ListItem key={`selected-recipient-${contactPerson}`}>
            <Grid container>
              <ListItemAvatar>
                <Avatar>{user ? `${user.firstName?.[0]}${user.lastName?.[0]}` : ''}</Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  user ? `${user.firstName} ${user.lastName}` : t('eventDetails.settings.tabs.contactData.deletedUser')
                }
                secondary={user ? user.emailAddress : ''}
                sx={{ flexGrow: 0 }}
              />
              {deleteActionVisibility && (
                <IconButton
                  sx={{ ml: 1 }}
                  onClick={() => {
                    dispatch({ type: 'remove-contact-person', payload: contactPerson });
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Grid>
          </ListItem>
        );
      })}

      {contactPersons.length === 0 && (
        <Autocomplete
          disabled={contactPersons.length > 0}
          blurOnSelect={true}
          value={value}
          onChange={(event, newValue) => {
            if (typeof newValue === 'string') {
              // timeout to avoid instant validation of the dialog's form.
              setTimeout(() => {
                setCreateUserDialogOpen(true);
                setCreateUserDialogValue({
                  emailAddress: newValue,
                  firstName: '',
                  lastName: '',
                });
              });
            } else if (newValue && newValue.inputValue) {
              setCreateUserDialogOpen(true);
              setCreateUserDialogValue({
                emailAddress: newValue.inputValue,
                firstName: '',
                lastName: '',
              });
            } else {
              const newId = newValue?.id;
              if (typeof newId !== 'undefined' && !contactPersons.includes(newId)) {
                dispatch({ type: 'add-contact-person', payload: newId });
                updateEmailForOtherParts(newValue?.emailAddress || '');
              }
              setValue(null);
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            if (
              params.inputValue !== '' &&
              !userOptions.find((item) => {
                return item.emailAddress === params.inputValue;
              })
            ) {
              filtered.push({
                inputValue: params.inputValue,
                emailAddress: `Add "${params.inputValue}"`,
              });
            }

            return filtered;
          }}
          options={[...userOptions].filter(optionsFilter)}
          getOptionLabel={(option) => {
            // e.g. value selected with enter, right from the input
            if (typeof option === 'string') {
              return option;
            }
            if (option.inputValue) {
              return option.inputValue;
            }
            return `${option.firstName} ${option.lastName} - ${option.emailAddress}`;
          }}
          selectOnFocus
          clearOnBlur
          clearOnEscape
          handleHomeEndKeys
          renderOption={(props, option) => (
            <AutoCompleteListItem key={`select-item-${option.id}`} props={props} user={option} />
          )}
          freeSolo
          renderInput={(params) => (
            <Tooltip
              title={
                contactPersons.length > 0 ? t('eventDetails.settings.tabs.contactData.justOneAnsprechpartner') : ''
              }
            >
              <FormControl margin="normal" fullWidth>
                <TextField
                  {...params}
                  label={t('eventDetails.settings.tabs.contactData.emailAddress')}
                  placeholder={t('eventDetails.settings.tabs.contactData.emailAddress')}
                  required
                  error={
                    !eventSettings.contactData?.contactPersonsCustomer ||
                    eventSettings.contactData.contactPersonsCustomer.length === 0
                  }
                />
              </FormControl>
            </Tooltip>
          )}
        />
      )}

      <Dialog open={createUserDialogOpen} onClose={handleCloseCreateUserDialog}>
        <DialogTitle>{t('eventDetails.settings.tabs.contactData.createUserLabel')}</DialogTitle>
        <DialogContent>
          {creatingUser && <LinearProgress />}
          <DialogContentText>
            {createUserError && (
              <Alert severity="error">{t('eventDetails.settings.tabs.contactData.errorCreateUser')}</Alert>
            )}
          </DialogContentText>

          <TextField
            fullWidth={true}
            autoFocus
            margin="dense"
            id="emailAddress"
            required
            value={createUserDialogValue.emailAddress}
            onChange={(event) =>
              setCreateUserDialogValue({
                ...createUserDialogValue,
                emailAddress: event.target.value,
              })
            }
            error={!checkMail(createUserDialogValue.emailAddress)}
            type="text"
            label={t('eventDetails.settings.tabs.contactData.emailAddress')}
            placeholder={t('eventDetails.settings.tabs.contactData.emailAddress')}
          />

          <TextField
            fullWidth={true}
            autoFocus
            margin="dense"
            id="firstName"
            required
            value={createUserDialogValue.firstName}
            error={createUserDialogValue.firstName.length === 0}
            onChange={(event) =>
              setCreateUserDialogValue({
                ...createUserDialogValue,
                firstName: event.target.value,
              })
            }
            label={t('eventDetails.settings.tabs.contactData.firstName')}
            placeholder={t('eventDetails.settings.tabs.contactData.firstName')}
            type="text"
          />
          <TextField
            fullWidth={true}
            margin="dense"
            id="Nachname"
            required
            value={createUserDialogValue.lastName}
            error={createUserDialogValue.lastName.length === 0}
            onChange={(event) =>
              setCreateUserDialogValue({
                ...createUserDialogValue,
                lastName: event.target.value,
              })
            }
            label={t('eventDetails.settings.tabs.contactData.lastName')}
            placeholder={t('eventDetails.settings.tabs.contactData.lastName')}
            type="text"
          />
        </DialogContent>
        <DialogActions sx={{ mb: 2, mx: 2 }}>
          <Button disabled={creatingUser} onClick={handleCloseCreateUserDialog}>
            {t('cancel')}
          </Button>
          <Button
            disabled={
              !checkMail(createUserDialogValue.emailAddress) ||
              createUserDialogValue.firstName.length === 0 ||
              createUserDialogValue.lastName.length === 0 ||
              creatingUser
            }
            onClick={handleCreateUser}
            type="submit"
            variant="contained"
          >
            {t('eventDetails.settings.tabs.contactData.createUser')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ContactPersons;
