import {
  Button,
  CircularProgress,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { IParticipant } from '@shared/interfaces';
import { FunctionComponent, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { useEventSettings } from '../../../Context/EventSettingsContext';
import { useDeleteParticipant, useEventParticipants } from '../../../../queries/participants';
import JWTContext from '../../../JWTContext';
import { ReadonlyField } from '../Common/ReadonlyField';
import { useQueryClient } from '@tanstack/react-query';
import ParticipantForm from './ParticipantForm';
import { Stack } from '@mui/system';
import CsvImport from './CsvImport';
import ConfirmationDialog from '../Common/ConfirmationDialog';
import { AlertContext } from '../../../AlertSnackbar/AlertContext';

interface IParticipantsTableProps {
  enableEdit: boolean;
}

interface IColumn {
  id: keyof IParticipant;
}

export const columns: IColumn[] = [
  {
    id: 'title',
  },
  {
    id: 'firstName',
  },
  {
    id: 'lastName',
  },
  {
    id: 'email',
  },
];

const ParticipantsTable: FunctionComponent<IParticipantsTableProps> = ({ enableEdit }) => {
  const { eventSettings } = useEventSettings();
  const { getJWT } = useContext(JWTContext);
  const [showParticipantForm, setShowParticipantForm] = useState(false);
  const [showDeleteParticipantDialog, setShowDeleteParticipantDialog] = useState(false);
  const [showCsvImport, setShowCsvImport] = useState(false);
  const [currentParticipant, setCurrentParticipant] = useState<IParticipant | null>(null);
  const { enqueueAlert } = useContext(AlertContext);

  const queryClient = useQueryClient();

  const { data: participants, isLoading: participantsLoading } = useEventParticipants(
    eventSettings?.eventId || '',
    getJWT
  );

  const {
    mutate: deleteParticipant,
    isPending: deletingParticipant,
    variables,
  } = useDeleteParticipant(getJWT, queryClient, eventSettings?.eventId);

  const handleCreateNewParticipant = () => {
    setShowParticipantForm(true);
  };

  const handleEditParticipant = (participant: IParticipant) => {
    setCurrentParticipant(participant);
    setShowParticipantForm(true);
  };

  const handleDialogClose = () => {
    setShowParticipantForm(false);
    setCurrentParticipant(null);
  };

  const handleDeleteParticipant = () => {
    if (currentParticipant && !!eventSettings?.eventId) {
      deleteParticipant({ eventId: eventSettings.eventId, participantId: currentParticipant.id });
    }
    enqueueAlert({
      message: t('eventDetails.settings.tabs.participants.alerts.deleteSuccess'),
      type: 'success',
    });
    setShowDeleteParticipantDialog(false);
    setCurrentParticipant(null);
  };

  const handleShowDeleteParticipantDialog = (participant: IParticipant) => {
    setShowDeleteParticipantDialog(true);
    setCurrentParticipant(participant);
  };

  const handleHideDeleteParticipantDialog = () => {
    setShowDeleteParticipantDialog(false);
    setCurrentParticipant(null);
  };

  const { t } = useTranslation();

  const showLoadingIndicator = participantsLoading;

  const emptyList = <ReadonlyField text={t('noDataAvailable')} variant="EMPTY" />;

  const confirmationDeleteActions = (
    <>
      <Button variant="contained" color="secondary" onClick={() => handleHideDeleteParticipantDialog()}>
        {t('cancel')}
      </Button>
      <Button variant="contained" color="primary" onClick={() => handleDeleteParticipant()} sx={{ ml: 3 }}>
        {t('delete')}
      </Button>
    </>
  );

  return (
    <>
      {showParticipantForm && <ParticipantForm handleClose={handleDialogClose} participant={currentParticipant} />}
      <ConfirmationDialog
        title={t('eventDetails.settings.tabs.participants.deleteUser')}
        open={showDeleteParticipantDialog}
        handleClose={() => {
          handleHideDeleteParticipantDialog();
        }}
        actions={confirmationDeleteActions}
      >
        {t('eventDetails.settings.tabs.participants.confirmDeleteUser')}
      </ConfirmationDialog>

      {showCsvImport && <CsvImport currentParticipants={participants} handleClose={() => setShowCsvImport(false)} />}

      {enableEdit && (
        <Stack my={2} direction={'row'} spacing={2}>
          <Button variant="outlined" color="primary" onClick={() => handleCreateNewParticipant()}>
            {t('eventDetails.settings.tabs.participants.addParticipant')}
          </Button>
          <Button variant="outlined" color="primary" onClick={() => setShowCsvImport(true)}>
            {t('eventDetails.settings.tabs.participants.uploadCsv')}
          </Button>
        </Stack>
      )}

      {showLoadingIndicator && <LinearProgress sx={{ mb: 2 }} />}

      {participants && participants.length > 0 && (
        <TableContainer sx={{ mt: 2 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={`head-${column.id}`}>
                    {t(`eventDetails.settings.tabs.participants.table.${column.id}`)}
                  </TableCell>
                ))}
                {enableEdit && <TableCell align="right" key="actions" />}
              </TableRow>
            </TableHead>
            <TableBody>
              {participants.map((participant) => {
                return (
                  <TableRow
                    sx={{ '&:hover': { cursor: 'default' } }}
                    hover={enableEdit}
                    tabIndex={-1}
                    key={participant.id}
                  >
                    {columns.map((column) => {
                      return <TableCell key={`body-${column.id}`}>{participant[column.id]}</TableCell>;
                    })}
                    {enableEdit && (
                      <TableCell key={`${participant.id}-actions`} align="right">
                        {deletingParticipant && variables.participantId === participant.id ? (
                          <CircularProgress size={35} />
                        ) : (
                          <>
                            <IconButton
                              onClick={() => {
                                handleShowDeleteParticipantDialog(participant);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                            <IconButton onClick={() => handleEditParticipant(participant)}>
                              <EditIcon />
                            </IconButton>
                          </>
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {!participants?.[0] && emptyList}
    </>
  );
};

export default ParticipantsTable;
