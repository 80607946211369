import { ColorPalette } from '@hmg-aka-lu/elevate-theme';
import { Box, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT } from '../../../../config';
import { format } from 'date-fns';

export type FeedbackStatus =
  | 'NOT_SENT_YET'
  | 'DISABLED'
  | 'NOT_SENT_DISABLED'
  | 'NOT_SENT_NO_FEEDBACK'
  | 'NOT_SENT_NO_RECIPIENT'
  | 'SCHEDULED'
  | 'SENT';

interface IFeedbackStatusProps {
  overallImpression: number | null;
  linkValidUntil?: string;
  nps: number | null;
  status: FeedbackStatus;
  recipients?: string[];
  sentToCustomerAt?: string;
}

const NPS_THRESHOLD = 10;
const OVERALL_IMPRESSION_THRESHOLD = 2.5;

export const FeedbackStatus: FunctionComponent<IFeedbackStatusProps> = ({
  overallImpression,
  linkValidUntil,
  nps,
  recipients,
  sentToCustomerAt,
  status,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Box>
        <Typography paragraph data-testid="feedback-status">
          {t('event.overview.statusLabel')}{' '}
          <Typography
            data-testid="feedback-status-value"
            sx={{ ...(status === 'SENT' || status === 'SCHEDULED' ? { color: ColorPalette.feedback.green } : {}) }}
            component="span"
          >
            {t(`event.overview.feedback.status.${status}`)}
          </Typography>
        </Typography>
      </Box>

      {(status === 'SENT' || status === 'SCHEDULED') && (
        <Box data-testid="feedback-link-data">
          <Box mt={1}>
            <Typography paragraph data-testid="feedback-sent-at">
              {status === 'SENT' ? t('event.overview.feedback.sentAt') : t('event.overview.feedback.scheduledAt')}{' '}
              {sentToCustomerAt && format(new Date(sentToCustomerAt), DATE_FORMAT)}
            </Typography>
          </Box>

          <Box mt={1}>
            <Typography paragraph data-testid="feedback-recipient">
              {t('event.overview.feedback.recipient')} {recipients?.join(', ')}
            </Typography>
          </Box>

          <Box mt={1}>
            <Typography paragraph data-testid="feedback-link-valid-to">
              {t('event.overview.feedback.linkValidTo')}{' '}
              {linkValidUntil && format(new Date(linkValidUntil), DATE_FORMAT)}
            </Typography>
          </Box>
        </Box>
      )}

      {nps && (
        <Box mt={1}>
          <Typography paragraph data-testid="feedback-nps">
            {t('event.overview.feedback.nps')}{' '}
            <Typography
              component="span"
              data-testid="feedback-nps-value"
              sx={{ ...(nps < NPS_THRESHOLD ? { color: ColorPalette.feedback.red } : {}) }}
            >
              {t('intlNumber', { val: nps })}
            </Typography>
          </Typography>
        </Box>
      )}

      {overallImpression && (
        <Box mt={1}>
          <Typography paragraph data-testid="feedback-overall-impression">
            {t('event.overview.feedback.overallImpression')}{' '}
            <Typography
              component="span"
              data-testid="feedback-overall-impression-value"
              sx={{
                ...(overallImpression > OVERALL_IMPRESSION_THRESHOLD ? { color: ColorPalette.feedback.red } : {}),
              }}
            >
              {t('intlNumber', { val: overallImpression })}
            </Typography>
          </Typography>
        </Box>
      )}
    </>
  );
};
