import { QueryClient, useMutation, useQuery } from '@tanstack/react-query';
import { getApiService } from '../api/api-request';

export const userQueryKeys = {
  debitorUsers: (debitorId?: string) => ['users', debitorId] as const,
};

interface ICreateUserMutationParams {
  emailAddress: string;
  firstName?: string;
  lastName?: string;
  debitorId: string;
}

export const useUsersList = (getJWT: () => Promise<string | undefined | null>, debitorId?: string) => {
  return useQuery({
    queryKey: userQueryKeys.debitorUsers(debitorId),
    queryFn: () => getApiService(getJWT).getUsers(debitorId),
    initialData: [],
    initialDataUpdatedAt: 0,
    staleTime: 600000,
    enabled: Boolean(debitorId),
  });
};

export const useCreateUser = (getJWT: () => Promise<string | undefined | null>, queryClient: QueryClient) => {
  return useMutation({
    mutationFn: (params: ICreateUserMutationParams) => getApiService(getJWT).createUser(params),
    onSuccess: (params) => queryClient.invalidateQueries({ queryKey: userQueryKeys.debitorUsers(params.debitorId) }),
  });
};
