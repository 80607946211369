import { FunctionComponent } from 'react';
import SectionSummary from '../Common/SectionSummary';
import useSectionVisibility from '../../../../hooks/useSectionVisibility';
import { useTranslation } from 'react-i18next';
import { IEventSettingsData } from '@shared/interfaces';
import { useEventSettings } from '../../../Context/EventSettingsContext';
import { ReadonlyField } from '../Common/ReadonlyField';
import { showAddressForm } from './SeminarDocumentsOnsiteForm';
import AddressReadOnly from '../Common/AddressReadOnly';
import SectionBlock, { ISummaryNotification, NOTIFICATION_TYPE } from '../Common/SectionBlock';
import { SummaryLabel } from '../Common/SummaryLabel';
import { resolveAddress } from '../../../../utils/address-resolver';
import deepEqual from 'deep-equal';
import { getAddressForCopy } from '../Common/getAddressCopy';

const SeminarDocumentsOnsiteSummary: FunctionComponent = () => {
  const sectionId: keyof IEventSettingsData = 'seminarDocuments';
  const { isVisible } = useSectionVisibility(sectionId);
  const { t } = useTranslation();
  const { eventSettings, snapshots } = useEventSettings();
  const seminarDocuments = eventSettings?.seminarDocuments;

  const sentToCustomerSnapshot = snapshots.find((snapshot) => snapshot.snapshotType === 'SENT_TO_CUSTOMER');
  const finishedByCustomerSnapshot = snapshots.find((snapshot) => snapshot.snapshotType === 'FINISHED_BY_CUSTOMER');

  const { seminarDocuments: sentToCustomerSeminarDocuments } = sentToCustomerSnapshot ?? {};
  const { seminarDocuments: finishedByCustomerSeminarDocuments } = finishedByCustomerSnapshot ?? {};

  const resolvedAddress = eventSettings ? resolveAddress(eventSettings, 'seminarDocuments') : undefined;
  const addressForCopy = resolvedAddress
    ? getAddressForCopy({ ...resolvedAddress, country: t(`countries.${resolvedAddress.country}`) })
    : undefined;

  const getSendWritingMaterialsNotification = (): ISummaryNotification | undefined => {
    if (finishedByCustomerSeminarDocuments?.sendWritingMaterials === true) {
      return {
        text: t('eventDetails.settings.tabs.summary.notifications.seminarDocuments.sendWritingMaterials.true'),
        notificationType: NOTIFICATION_TYPE[0],
      };
    }

    return undefined;
  };

  const getRecipientEmailAddressesNotification = (): ISummaryNotification | undefined => {
    if (
      !deepEqual(
        sentToCustomerSeminarDocuments?.recipientEmailAddresses,
        finishedByCustomerSeminarDocuments?.recipientEmailAddresses
      )
    ) {
      return {
        text: t('eventDetails.settings.tabs.summary.notifications.seminarDocuments.recipientEmailAddresses.changed'),
        notificationType: NOTIFICATION_TYPE[1],
      };
    }

    return undefined;
  };

  const getSeminarDocumentAddressNotification = (): ISummaryNotification | undefined => {
    if (
      sentToCustomerSeminarDocuments?.recipientAddressRef !== finishedByCustomerSeminarDocuments?.recipientAddressRef
    ) {
      return {
        text: t('eventDetails.settings.tabs.summary.notifications.seminarDocuments.recipientAddress.changed'),
        notificationType: NOTIFICATION_TYPE[1],
      };
    }

    if (
      finishedByCustomerSeminarDocuments?.recipientAddressRef === 'seminarDocuments' &&
      !deepEqual(sentToCustomerSeminarDocuments?.recipientAddress, finishedByCustomerSeminarDocuments?.recipientAddress)
    ) {
      return {
        text: t(
          'eventDetails.settings.tabs.summary.notifications.seminarDocuments.recipientAddress.customAddressChanged'
        ),
        notificationType: NOTIFICATION_TYPE[1],
      };
    }

    return undefined;
  };

  return (
    <SectionSummary title={t(`eventDetails.settings.tabs.summary.${sectionId}.title`)} hidden={!isVisible}>
      <SectionBlock>
        <SummaryLabel label={t('eventDetails.settings.tabs.seminarDocuments.formatLabel')} />
        <ReadonlyField
          variant={seminarDocuments?.format == 'noSU' ? 'FALSE' : 'TRUE'}
          text={t(`eventDetails.settings.tabs.seminarDocuments.formats.${seminarDocuments?.format || 'digital'}`)}
        />
      </SectionBlock>

      <SectionBlock>
        <SummaryLabel label={t('eventDetails.settings.tabs.seminarDocuments.additionalDocuments.label')} />
        <ReadonlyField
          variant={seminarDocuments?.additionalDocuments === true ? 'TRUE' : 'FALSE'}
          text={t(
            `eventDetails.settings.tabs.seminarDocuments.additionalDocuments.${
              seminarDocuments?.additionalDocuments === true ? 'yes' : 'notAvailable'
            }`
          )}
        />
      </SectionBlock>

      <SectionBlock
        data-testid="seminar-documents-send-writing-materials"
        notification={getSendWritingMaterialsNotification()}
      >
        <SummaryLabel
          label={t('eventDetails.settings.tabs.seminarDocuments.sendWritingMaterialsLabel')}
          copyText={
            t('eventDetails.settings.tabs.seminarDocuments.sendWritingMaterialsLabel') +
            ': ' +
            t(
              `eventDetails.settings.tabs.seminarDocuments.sendWritingMaterials.${
                seminarDocuments?.sendWritingMaterials === true ? 'yes' : 'notAvailable'
              }`
            )
          }
        />
        <ReadonlyField
          variant={seminarDocuments?.sendWritingMaterials === true ? 'TRUE' : 'FALSE'}
          text={t(
            `eventDetails.settings.tabs.seminarDocuments.sendWritingMaterials.${
              seminarDocuments?.sendWritingMaterials === true ? 'yes' : 'notAvailable'
            }`
          )}
        />
      </SectionBlock>

      {seminarDocuments?.format === 'digital' && (
        <SectionBlock
          data-testid="seminar-documents-recipient-email-addresses"
          notification={getRecipientEmailAddressesNotification()}
        >
          <SummaryLabel
            label={t('eventDetails.settings.tabs.seminarDocuments.emailRecipientsLabel')}
            copyText={
              seminarDocuments.recipientEmailAddresses?.[0]
                ? t('eventDetails.settings.tabs.seminarDocuments.emailRecipientsLabel') +
                  ': ' +
                  seminarDocuments.recipientEmailAddresses?.[0]
                : undefined
            }
          />
          <ReadonlyField
            variant="TEXT"
            error={!seminarDocuments.recipientEmailAddresses?.[0]}
            text={seminarDocuments.recipientEmailAddresses?.[0] ?? t('missingData')}
          />
        </SectionBlock>
      )}

      {showAddressForm(
        seminarDocuments?.format,
        !!seminarDocuments?.additionalDocuments,
        !!seminarDocuments?.sendWritingMaterials
      ) && (
        <SectionBlock
          data-testid="seminar-documents-recipient-address"
          notification={getSeminarDocumentAddressNotification()}
        >
          <SummaryLabel
            label={t('eventDetails.settings.tabs.seminarDocuments.recipientAddressLabel')}
            copyText={addressForCopy}
          />
          {eventSettings && <AddressReadOnly address={resolveAddress(eventSettings, 'seminarDocuments')} />}
        </SectionBlock>
      )}
    </SectionSummary>
  );
};

export default SeminarDocumentsOnsiteSummary;
