import { FunctionComponent } from 'react';
import { ReadonlyField } from '../Common/ReadonlyField';
import { DefaultLabel } from '../Common/DefaultLabel';
import { Trans, useTranslation } from 'react-i18next';
import { useEventSettings } from '../../../Context/EventSettingsContext';
import SectionBlock from '../Common/SectionBlock';

const FeedbackReadonly: FunctionComponent = () => {
  const { event, eventSettings } = useEventSettings();
  const { t } = useTranslation();

  if (!eventSettings || !event) return <></>;

  const { enabled, surveyType, recipientEmailAddresses } = eventSettings.feedback ?? {};

  const defaultLabel = t(
    `eventDetails.settings.tabs.feedback.surveyType.DEFAULT.${!!event.isOnline ? 'ONLINE' : 'ONSITE'}`
  );

  return (
    <>
      <SectionBlock>
        <DefaultLabel
          alertText={t('eventDetails.settings.tabs.feedback.feedbackHint')}
          alertTitle={t('generalHintTitle')}
          label={t('eventDetails.settings.tabs.feedback.hintLabel')}
          alertSeverity="info"
        />
        {enabled ? (
          <ReadonlyField text={t('eventDetails.settings.tabs.feedback.haufeFeedbackSystem')} variant="TRUE" />
        ) : (
          <ReadonlyField text={t('eventDetails.settings.tabs.feedback.otherFeedbackSystem')} variant="FALSE" />
        )}
      </SectionBlock>

      {enabled && (
        <>
          <SectionBlock>
            {event?.businessUnit === 'IHC' && (
              <>
                <DefaultLabel
                  alertText={t(`eventDetails.settings.tabs.feedback.surveyTypeFormHint.ReadOnly`)}
                  alertTitle={t('generalHintTitle')}
                  label={t('eventDetails.settings.tabs.feedback.surveyTypeLabel')}
                  alertSeverity="info"
                />
                <ReadonlyField
                  text={
                    surveyType === 'DEFAULT'
                      ? defaultLabel
                      : t('eventDetails.settings.tabs.feedback.surveyType.ASSESSMENT_CENTER')
                  }
                  variant="TEXT"
                />
              </>
            )}
          </SectionBlock>

          <SectionBlock>
            <DefaultLabel
              alertText={<Trans i18nKey={'eventDetails.settings.tabs.feedback.recipientHint'} />}
              alertTitle={t('generalHintTitle')}
              label={t('eventDetails.settings.tabs.feedback.recipientLabel')}
              alertSeverity="info"
            />
            <ReadonlyField
              text={recipientEmailAddresses?.[0] || t('missingData')}
              error={!recipientEmailAddresses?.[0]}
              variant="TEXT"
            />
          </SectionBlock>
        </>
      )}
    </>
  );
};

export default FeedbackReadonly;
