import { FunctionComponent, ReactNode } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material';

interface IDialogProps {
  title: string;
  children: ReactNode;
  actions?: ReactNode;
  handleClose: () => void;
  open: boolean;
}

const ConfirmationDialog: FunctionComponent<IDialogProps> = ({ title, children, actions, handleClose, open }) => {
  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <DialogContentText>{children}</DialogContentText>
      </DialogContent>
      {actions && <DialogActions sx={{ p: 2 }}>{actions}</DialogActions>}
    </Dialog>
  );
};

export default ConfirmationDialog;
