import { FunctionComponent } from 'react';
import { ReadonlyField } from '../Common/ReadonlyField';
import { DefaultLabel } from '../Common/DefaultLabel';
import { Trans, useTranslation } from 'react-i18next';
import { useEventSettings } from '../../../Context/EventSettingsContext';
import AddressReadOnly from '../Common/AddressReadOnly';
import { showAddressForm } from './SeminarDocumentsOnsiteForm';
import SectionBlock from '../Common/SectionBlock';
import { resolveAddress } from '../../../../utils/address-resolver';

const SeminarDocumentsOnsiteReadonly: FunctionComponent = () => {
  const { t } = useTranslation();
  const { eventSettings } = useEventSettings();

  if (!eventSettings) return <></>;

  const { format, additionalDocuments, sendWritingMaterials, recipientEmailAddresses } =
    eventSettings.seminarDocuments ?? {};

  return (
    <>
      <SectionBlock>
        <DefaultLabel
          alertTitle={t('generalHintTitle')}
          label={t('eventDetails.settings.tabs.seminarDocuments.formatLabel')}
          alertText={t('eventDetails.settings.tabs.seminarDocuments.formatHintReadOnly')}
        />
        <ReadonlyField
          variant={format == 'noSU' ? 'FALSE' : 'TRUE'}
          text={t(`eventDetails.settings.tabs.seminarDocuments.formats.${format || 'digital'}`)}
        />
      </SectionBlock>

      <SectionBlock>
        <DefaultLabel
          alertTitle={t('generalHintTitle')}
          label={t('eventDetails.settings.tabs.seminarDocuments.additionalDocuments.label')}
          alertText={t('eventDetails.settings.tabs.seminarDocuments.additionalDocumentsHintReadOnly')}
        />
        <ReadonlyField
          variant={additionalDocuments === true ? 'TRUE' : 'FALSE'}
          text={t(
            `eventDetails.settings.tabs.seminarDocuments.additionalDocuments.${
              additionalDocuments === true ? 'yes' : 'notAvailable'
            }`
          )}
        />
      </SectionBlock>

      <SectionBlock>
        <DefaultLabel
          alertTitle={t('generalHintTitle')}
          label={t('eventDetails.settings.tabs.seminarDocuments.sendWritingMaterialsLabel')}
          alertText={t('eventDetails.settings.tabs.seminarDocuments.sendWritingMaterialsHintReadOnly')}
        />
        <ReadonlyField
          variant={sendWritingMaterials === true ? 'TRUE' : 'FALSE'}
          text={t(
            `eventDetails.settings.tabs.seminarDocuments.sendWritingMaterials.${
              sendWritingMaterials === true ? 'yes' : 'notAvailable'
            }`
          )}
        />
      </SectionBlock>

      {format === 'digital' && (
        <SectionBlock>
          <DefaultLabel
            alertTitle={t('generalHintTitle')}
            label={t('eventDetails.settings.tabs.seminarDocuments.emailRecipientsLabel')}
            alertText={<Trans i18nKey={'eventDetails.settings.tabs.seminarDocuments.recipientEmailHintReadonly'} />}
          />
          <ReadonlyField
            variant="TEXT"
            error={!recipientEmailAddresses?.[0]}
            text={recipientEmailAddresses?.[0] ?? t('missingData')}
          />
        </SectionBlock>
      )}
      {showAddressForm(format, !!additionalDocuments, !!sendWritingMaterials) && (
        <SectionBlock>
          <DefaultLabel
            alertTitle={t('generalHintTitle')}
            label={t('eventDetails.settings.tabs.seminarDocuments.recipientAddressLabel')}
            alertText={<Trans i18nKey={'eventDetails.settings.tabs.seminarDocuments.recipientAddressHintReadonly'} />}
          />
          <AddressReadOnly address={resolveAddress(eventSettings, 'seminarDocuments')} />
        </SectionBlock>
      )}
    </>
  );
};

export default SeminarDocumentsOnsiteReadonly;
