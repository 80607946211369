import { IAddress } from '@shared/interfaces';

export const getAddressForCopy = (address: Partial<IAddress>) => {
  const addressFields = [
    address.line1,
    address.line2,
    address.street,
    address.postalCode && address.city ? address.postalCode + ' ' + address.city : address.postalCode ?? address.city,
    address.country,
  ].filter((item) => item !== undefined);

  return addressFields.join('\n');
};
