import { IAddress } from '@shared/interfaces';
import { FunctionComponent } from 'react';
import { ReadonlyField, ReadonlyFieldProps } from './ReadonlyField';
import { useTranslation } from 'react-i18next';

interface IAddressProps {
  address?: Partial<IAddress>;
}

// ...rest is needed for data-testid attributes, removing it will break tests
const AddressReadOnly: FunctionComponent<IAddressProps> = ({ address, ...rest }) => {
  const { t } = useTranslation();
  const noData = t('noData');

  const getReadOnlyFields = (input: string | undefined) => {
    const isEmpty = !(input && input.length > 0);

    const text = !isEmpty ? input : noData;
    return { text, variant: !isEmpty ? 'TEXT' : 'EMPTY', error: isEmpty } as ReadonlyFieldProps;
  };

  const getPlzAndStreet = () => {
    return [address?.postalCode, address?.city].join(' ');
  };

  return address ? (
    <div {...rest}>
      <ReadonlyField {...getReadOnlyFields(address?.line1)} />
      {address?.line2 && <ReadonlyField text={address?.line2 || ''} variant="TEXT" />}
      <ReadonlyField {...getReadOnlyFields(address?.street)} />
      <ReadonlyField {...getReadOnlyFields(getPlzAndStreet())} />
      <ReadonlyField {...getReadOnlyFields(t(`countries.${address.country}`))} />
    </div>
  ) : (
    <ReadonlyField text={t('missingData')} variant="TEXT" error={true} />
  );
};

export default AddressReadOnly;
