import { Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import CopyButton from './CopyButton';

interface IAlertItemProps {
  label: string;
  copyText?: string;
}

export const SummaryLabel: FunctionComponent<IAlertItemProps> = ({ label, copyText }) => {
  return (
    <Typography variant="headingXs" alignItems={'center'} mb={0}>
      {label}
      <CopyButton textToCopy={copyText} />
    </Typography>
  );
};
