import { Box, Button, FormControl, FormControlLabel, IconButton, Radio, Tooltip, Typography } from '@mui/material';
import { ADDRESS_SECTIONS, IAddress } from '@shared/interfaces';
import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddressFormDialog from './AddressFormDialog';
import AddressReadOnly from './AddressReadOnly';

export interface ISelectableAddress {
  address: Partial<IAddress>;
  key: ADDRESS_SECTIONS;
  label: string;
  selected: boolean;
  onSelect: () => void;
}

interface IAddressProps {
  selectableAddresses: ISelectableAddress[];
  customAddress?: Partial<IAddress>;
  customAddressSelected: boolean;
  onSelectCustomAddress: () => void;
  onChangeCustomAddress: (address: Partial<IAddress> | undefined) => void;
  onRemoveCustomAddress: () => void;
}

const AddressMultiForm: FunctionComponent<IAddressProps> = (props) => {
  const { t } = useTranslation();

  const [showCustomAddressForm, setShowCustomAddressForm] = useState(false);

  return (
    <>
      <FormControl data-testid={`address-list`}>
        {props.selectableAddresses.map((address) => (
          <FormControlLabel
            data-testid="address-list-item"
            key={address.key}
            sx={{ alignItems: 'flex-start', mt: 2 }}
            control={
              <Radio
                data-testid={`address-list-radio-button-${address.key}`}
                name="address-select"
                onChange={() => address.onSelect()}
                checked={address.selected}
              />
            }
            label={
              <>
                <Typography data-testid={`address-list-label-${address.key}`} mt={1} paragraph variant="headingXxs">
                  {address.label}
                </Typography>
                <AddressReadOnly data-testid={`address-list-address-${address.key}`} address={address.address} />
              </>
            }
          />
        ))}

        {props.customAddress ? (
          <Box display={'flex'}>
            <FormControlLabel
              data-testid="custom-address-container"
              sx={{ alignItems: 'flex-start', mt: 2 }}
              control={
                <Radio
                  data-testid="address-list-radio-button-custom"
                  name="address-select"
                  onChange={() => props.onSelectCustomAddress()}
                  checked={props.customAddressSelected}
                />
              }
              label={
                <>
                  <Typography mt={1} paragraph variant="headingXxs">
                    {t('address.customAddress')}
                  </Typography>
                  <AddressReadOnly data-testid="custom-address" address={props.customAddress} />
                </>
              }
            />
            <Tooltip title={t('address.editAddress')}>
              <IconButton
                data-testid="edit-custom-address"
                onClick={() => setShowCustomAddressForm(true)}
                sx={{ alignSelf: 'center', marginLeft: 2 }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('address.deleteAddress')}>
              <IconButton
                data-testid="remove-custom-address"
                onClick={() => props.onRemoveCustomAddress()}
                sx={{ alignSelf: 'center' }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
        ) : (
          <Button
            data-testid="add-new-address-button"
            onClick={() => setShowCustomAddressForm(true)}
            sx={{ mt: 2 }}
            startIcon={<AddIcon />}
          >
            {t('address.addNewAddress')}
          </Button>
        )}
      </FormControl>

      {showCustomAddressForm && (
        <AddressFormDialog
          address={props.customAddress}
          openDialog={setShowCustomAddressForm}
          onChange={props.onChangeCustomAddress}
        />
      )}
    </>
  );
};

export default AddressMultiForm;
