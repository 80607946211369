import {
  BusinessUnit,
  EventSettingsStatus,
  IAblauf,
  IAddress,
  ICapacity,
  IEventSettingsData,
  IEventSettingsResponse,
  IEventSettingsSnapshot,
  IInternalContactPersons,
  ILocationSettings,
  ISeminarDocumentSettings,
  IUpdateEventSettingsRequestBody,
  Sections,
} from '@shared/interfaces';
import deepmerge from 'deepmerge';
import { Dispatch, FunctionComponent, PropsWithChildren, createContext, useContext, useReducer } from 'react';

export interface IEventProps {
  businessUnit: BusinessUnit;
  isOnline?: boolean;
  internalContactPersons: IInternalContactPersons[];
  ablauf: IAblauf[];
  capacity?: ICapacity;
  location: {
    name: string;
    isClientTool: boolean;
  };
}

interface IEventSettingsState {
  eventSettings: IEventSettingsResponse | IEventSettingsSnapshot | null;
  eventSettingsOriginal: IEventSettingsResponse | null;
  debitorId: string;
  dirty: Partial<Record<keyof IEventSettingsData, boolean>>;
  event: IEventProps | null;
  snapshots: IEventSettingsSnapshot[];
}

const initialState: IEventSettingsState = {
  eventSettings: null,
  eventSettingsOriginal: null,
  debitorId: 'unknown',
  dirty: {},
  event: null,
  snapshots: [],
};

interface IUpdateEventSettingsAction {
  type: 'update';
  payload: IUpdateEventSettingsRequestBody;
}

interface IAddContactPersonAction {
  type: 'add-contact-person';
  payload: string;
}

interface IRemoveContactPersonAction {
  type: 'remove-contact-person';
  payload: string;
}

interface IUpdateRoomSetupEquipmentAction {
  type: 'update-room-setup-equipment';
  payload: {
    id: string;
    params?: Record<string, string>;
  };
}

interface IAddRoomSetupEquipmentAction {
  type: 'add-room-setup-equipment';
  payload: {
    id: string;
    params?: Record<string, string>;
  };
}

interface IRemoveRoomSetupEquipmentAction {
  type: 'remove-room-setup-equipment';
  payload: {
    id: string;
  };
}

interface IToggleHiddenSectionAction {
  type: 'toggle-hidden-section';
  payload: Sections;
}

export type EmailRecipientSection = 'seminarDocuments' | 'feedback' | 'confirmationOfParticipation';

interface IUpdateEmailRecipientAction {
  type: 'update-email-recipient';
  payload: {
    section: EmailRecipientSection;
    index: number;
    newValue: string;
  };
}

interface ISetSingleEmailRecipientAction {
  type: 'set-single-email-recipient';
  payload: {
    section: EmailRecipientSection;
    value: string;
  };
}

interface IAddEmailRecipientAction {
  type: 'add-email-recipient';
  payload: {
    section: EmailRecipientSection;
    value: string;
  };
}

interface IRemoveEmailRecipientAction {
  type: 'remove-email-recipient';
  payload: {
    section: EmailRecipientSection;
    value: string;
  };
}

interface IResetDirtyState {
  type: 'reset-dirty-state';
}

interface IResetContextState {
  type: 'reset-context';
}

interface IUpdateEventsettingsContextState {
  type: 'update-event-settings-original';
}

interface IChangeStatusEventSettingsAction {
  type: 'change-status';
  payload: {
    status: EventSettingsStatus;
  };
}

interface IUpdateInitialContactAddress {
  type: 'update-contact-address';
  payload?: Partial<IAddress>;
}

type IEventSettingsActions =
  | IUpdateEventSettingsAction
  | IChangeStatusEventSettingsAction
  | IAddContactPersonAction
  | IAddEmailRecipientAction
  | IAddRoomSetupEquipmentAction
  | IRemoveContactPersonAction
  | IRemoveEmailRecipientAction
  | IRemoveRoomSetupEquipmentAction
  | IToggleHiddenSectionAction
  | IUpdateEmailRecipientAction
  | IUpdateRoomSetupEquipmentAction
  | ISetSingleEmailRecipientAction
  | IResetDirtyState
  | IUpdateEventsettingsContextState
  | IResetContextState
  | IUpdateInitialContactAddress;

export const EventSettingsContext = createContext<IEventSettingsState>(initialState);
export const EventSettingsDispatchContext = createContext<Dispatch<IEventSettingsActions>>(() => null);

export interface IEventSettingsProviderProps {
  eventSettings: IEventSettingsResponse;
  debitorId: string;
  event: IEventProps;
  snapshots: IEventSettingsSnapshot[];
}

export const EventSettingssProvider: FunctionComponent<PropsWithChildren<IEventSettingsProviderProps>> = (props) => {
  const sentToCustomerSnapshot = props.snapshots.find((snapshot) => snapshot.snapshotType === 'SENT_TO_CUSTOMER');

  const [eventSettings, dispatch] = useReducer(eventSettingsReducer, {
    eventSettings:
      (props.eventSettings.status === 'SENT_TO_CUSTOMER' ? sentToCustomerSnapshot : props.eventSettings) ??
      props.eventSettings,
    eventSettingsOriginal: props.eventSettings,
    debitorId: props.debitorId,
    dirty: {},
    event: props.event,
    snapshots: props.snapshots,
  });

  return (
    <EventSettingsContext.Provider value={eventSettings}>
      <EventSettingsDispatchContext.Provider value={dispatch}>{props.children}</EventSettingsDispatchContext.Provider>
    </EventSettingsContext.Provider>
  );
};

export const useEventSettings = () => {
  return useContext(EventSettingsContext);
};

export const useEventSettingsDispatch = () => {
  return useContext(EventSettingsDispatchContext);
};

const eventSettingsReducer = (state: IEventSettingsState, action: IEventSettingsActions): IEventSettingsState => {
  if (!state.eventSettings || 'snapshotType' in state.eventSettings) {
    return state;
  }

  switch (action.type) {
    case 'update': {
      const key = Object.keys(action.payload)[0];

      return {
        ...state,
        eventSettings: deepmerge<IEventSettingsResponse>(state.eventSettings, action.payload),
        dirty: {
          ...state.dirty,
          [key]: true,
        },
      };
    }

    case 'change-status': {
      const key = action.payload.status;
      return {
        ...state,
        eventSettings: {
          ...state.eventSettings,
          status: key,
        },
      };
    }

    case 'add-contact-person': {
      const currentContactPersons = state.eventSettings.contactData?.contactPersonsCustomer ?? [];
      return {
        ...state,
        eventSettings: {
          ...state.eventSettings,
          contactData: {
            ...state.eventSettings.contactData,
            contactPersonsCustomer: [...currentContactPersons, action.payload],
          },
        },
        dirty: {
          ...state.dirty,
          contactData: true,
        },
      };
    }

    case 'remove-contact-person': {
      const newContactPersons = [...(state.eventSettings.contactData?.contactPersonsCustomer ?? [])].filter(
        (item) => item !== action.payload
      );
      return {
        ...state,
        eventSettings: {
          ...state.eventSettings,
          contactData: {
            ...state.eventSettings.contactData,
            contactPersonsCustomer: newContactPersons,
          },
        },
        dirty: {
          ...state.dirty,
          contactData: true,
        },
      };
    }

    case 'update-contact-address': {
      const location: ILocationSettings | undefined = state.eventSettingsOriginal?.location?.eventLocation
        ? undefined
        : {
            ...state.eventSettings.location,
            eventLocation: action.payload,
            eventLocationRef: 'location',
          };

      const seminarDocuments: ISeminarDocumentSettings | undefined = state.eventSettingsOriginal?.seminarDocuments
        ?.recipientAddress
        ? undefined
        : {
            ...state.eventSettings.seminarDocuments,
            recipientAddress: action.payload,
            recipientAddressRef: 'seminarDocuments',
          };

      return {
        ...state,
        eventSettings: {
          ...state.eventSettings,
          contactData: {
            ...state.eventSettings.contactData,
            customerAddress: action.payload,
          },
          ...(location ? { location } : {}),
          ...(seminarDocuments ? { seminarDocuments } : {}),
        },
        dirty: {
          ...state.dirty,
          contactData: true,
        },
      };
    }

    case 'update-room-setup-equipment': {
      const updatedEquipment = [
        ...[...(state.eventSettings.roomSetup?.equipment ?? [])].filter((item) => item.id !== action.payload.id),
        { id: action.payload.id, params: action.payload.params },
      ];
      return {
        ...state,
        eventSettings: {
          ...state.eventSettings,
          roomSetup: {
            ...state.eventSettings.roomSetup,
            equipment: updatedEquipment,
          },
        },
        dirty: {
          ...state.dirty,
          roomSetup: true,
        },
      };
    }

    case 'add-room-setup-equipment': {
      return {
        ...state,
        eventSettings: {
          ...state.eventSettings,
          roomSetup: {
            ...state.eventSettings.roomSetup,
            equipment: [
              ...(state.eventSettings.roomSetup?.equipment ?? []),
              { id: action.payload.id, params: action.payload.params },
            ],
          },
        },
        dirty: {
          ...state.dirty,
          roomSetup: true,
        },
      };
    }

    case 'remove-room-setup-equipment': {
      return {
        ...state,
        eventSettings: {
          ...state.eventSettings,
          roomSetup: {
            ...state.eventSettings.roomSetup,
            equipment: [...(state.eventSettings.roomSetup?.equipment ?? [])].filter(
              (item) => item.id !== action.payload.id
            ),
          },
        },
        dirty: {
          ...state.dirty,
          roomSetup: true,
        },
      };
    }

    case 'toggle-hidden-section': {
      const sectionIsHidden = state.eventSettings.hiddenSections.includes(action.payload);

      const updatedHiddenSections = sectionIsHidden
        ? [...state.eventSettings.hiddenSections].filter((item) => item !== action.payload)
        : [...state.eventSettings.hiddenSections, action.payload];

      return {
        ...state,
        eventSettings: {
          ...state.eventSettings,
          hiddenSections: updatedHiddenSections,
        },
      };
    }

    case 'add-email-recipient': {
      const currentRecipients = state.eventSettings[action.payload.section]?.recipientEmailAddresses ?? [];
      return {
        ...state,
        eventSettings: {
          ...state.eventSettings,
          [action.payload.section]: {
            ...state.eventSettings[action.payload.section],
            recipientEmailAddresses: [...currentRecipients, action.payload.value],
          },
        },
        dirty: {
          ...state.dirty,
          [action.payload.section]: true,
        },
      };
    }

    case 'remove-email-recipient': {
      const newRecipients = [...(state.eventSettings[action.payload.section]?.recipientEmailAddresses ?? [])].filter(
        (item) => item !== action.payload.value
      );

      return {
        ...state,
        eventSettings: {
          ...state.eventSettings,
          [action.payload.section]: {
            ...state.eventSettings[action.payload.section],
            recipientEmailAddresses: newRecipients,
          },
        },
        dirty: {
          ...state.dirty,
          [action.payload.section]: true,
        },
      };
    }

    case 'set-single-email-recipient': {
      return {
        ...state,
        eventSettings: {
          ...state.eventSettings,
          [action.payload.section]: {
            ...state.eventSettings[action.payload.section],
            recipientEmailAddresses: Boolean(action.payload.value) ? [action.payload.value] : [],
          },
        },
        dirty: {
          ...state.dirty,
          [action.payload.section]: true,
        },
      };
    }

    case 'update-email-recipient': {
      const newRecipients = state.eventSettings[action.payload.section]?.recipientEmailAddresses?.map(
        (currentEmailAddress, currentIndex) => {
          if (action.payload.index === currentIndex) {
            return action.payload.newValue;
          }

          return currentEmailAddress;
        }
      );

      return {
        ...state,
        eventSettings: {
          ...state.eventSettings,
          [action.payload.section]: {
            ...state.eventSettings[action.payload.section],
            recipientEmailAddresses: newRecipients,
          },
        },
        dirty: {
          ...state.dirty,
          [action.payload.section]: true,
        },
      };
    }

    case 'reset-dirty-state': {
      return {
        ...state,
        dirty: {},
      };
    }

    case 'update-event-settings-original': {
      return {
        ...state,
        eventSettingsOriginal: state.eventSettings,
        dirty: {},
      };
    }

    case 'reset-context': {
      return {
        ...state,
        eventSettings: state.eventSettingsOriginal,
        dirty: {},
      };
    }

    default: {
      throw Error('Unknown action: ');
    }
  }
};
