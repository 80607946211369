import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Equipment from './Equipment';
import { useEventSettings, useEventSettingsDispatch } from '../../../Context/EventSettingsContext';
import { DefaultLabel } from '../Common/DefaultLabel';
import { TextField } from '@mui/material';
import SectionBlock from '../Common/SectionBlock';
import { ReadonlyField } from '../Common/ReadonlyField';

const RoomSetupForm: FunctionComponent<unknown> = () => {
  const { eventSettings } = useEventSettings();
  const { t } = useTranslation();

  const eventSettingsDispatch = useEventSettingsDispatch();

  if (!eventSettings) return <></>;

  const { equipment, equipmentSpecifics, notes } = eventSettings.roomSetup ?? {};

  return (
    <>
      <SectionBlock>
        <DefaultLabel
          alertText={t('eventDetails.settings.tabs.roomSetup.roomSetupHint')}
          alertTitle={t('generalHintTitle')}
          label={t('eventDetails.settings.tabs.roomSetup.neededRoomSetupLabel')}
          alertSeverity="info"
        />
        <Equipment equipment={equipment ?? []} />
      </SectionBlock>

      <SectionBlock>
        <DefaultLabel
          alertText={t('eventDetails.settings.tabs.roomSetup.equipmentSpecificsHint')}
          alertTitle={t('generalHintTitle')}
          label={t('eventDetails.settings.tabs.roomSetup.equipmentSpecificsLabel')}
          alertSeverity="info"
        />
        <TextField
          value={equipmentSpecifics ?? ''}
          onChange={(e) =>
            eventSettingsDispatch({
              type: 'update',
              payload: { roomSetup: { equipmentSpecifics: e.target.value } },
            })
          }
          margin="normal"
          fullWidth
        />
      </SectionBlock>

      <SectionBlock>
        <DefaultLabel
          alertText={t('eventDetails.settings.tabs.roomSetup.notesHint')}
          alertTitle={t('generalHintTitle')}
          label={t('eventDetails.settings.tabs.roomSetup.notesLabel')}
          alertSeverity="info"
        />
        {notes ? <ReadonlyField text={notes} variant="TEXT" /> : <ReadonlyField text={t('noData')} variant="EMPTY" />}
      </SectionBlock>
    </>
  );
};

export default RoomSetupForm;
