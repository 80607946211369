import { Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ColorPalette } from '@hmg-aka-lu/elevate-theme';

export interface ReadonlyFieldProps {
  variant: 'TRUE' | 'FALSE' | 'DATE' | 'TIME' | 'HIDDEN' | 'VISIBLE' | 'EMPTY' | 'TEXT' | 'DOCUMENT';
  text: string;
  error?: boolean;
}

export const ReadonlyField: FunctionComponent<ReadonlyFieldProps> = ({ variant, text, error = false }) => {
  let icon: JSX.Element | null = null;

  const iconStyle = {
    color: ColorPalette.text.brand,
    marginRight: 0.5,
  };

  switch (variant) {
    case 'TRUE':
      icon = <CheckIcon sx={iconStyle} />;
      break;
    case 'FALSE':
      icon = <CancelIcon sx={iconStyle} />;
      break;
    case 'DATE':
      icon = <CalendarMonthIcon sx={iconStyle} />;
      break;
    case 'TIME':
      icon = <AccessTimeIcon sx={iconStyle} />;
      break;
    case 'HIDDEN':
      icon = <VisibilityOffIcon sx={iconStyle} />;
      break;
    case 'VISIBLE':
      icon = <VisibilityIcon sx={iconStyle} />;
      break;
    default:
      break;
  }

  return (
    <Typography
      key={text.split('.')[-1]}
      display={'flex'}
      mb={0.5}
      alignItems={'center'}
      {...(variant === 'EMPTY' && {
        fontStyle: 'italic',
        ...(error ? { color: ColorPalette.feedback.red } : {}),
      })}
      {...(variant === 'TEXT' && {
        fontStyle: 'inherit',
        ...(error ? { color: ColorPalette.feedback.red } : {}),
      })}
    >
      {icon} {text}
    </Typography>
  );
};
