import { FunctionComponent } from 'react';
import { DefaultLabel } from '../Common/DefaultLabel';
import { useTranslation } from 'react-i18next';
import { useEventSettings } from '../../../Context/EventSettingsContext';
import { ReadonlyField } from '../Common/ReadonlyField';
import SectionBlock from '../Common/SectionBlock';

const RoomSetupReadonly: FunctionComponent = () => {
  const { eventSettings } = useEventSettings();

  if (!eventSettings) {
    return <></>;
  }

  const { equipment, equipmentSpecifics, notes } = eventSettings.roomSetup ?? {};
  const { t } = useTranslation();

  return (
    <>
      <SectionBlock>
        <DefaultLabel
          alertText={t('eventDetails.settings.tabs.roomSetup.roomSetupHint')}
          alertTitle={t('generalHintTitle')}
          label={t('eventDetails.settings.tabs.roomSetup.neededRoomSetupLabel')}
          alertSeverity="info"
        />
        {(!equipment || equipment.length === 0) && <ReadonlyField text={t('noData')} variant="EMPTY" />}
        {equipment &&
          equipment?.length > 0 &&
          equipment?.map((equipmentItem) => {
            const id = equipmentItem.id;
            const params = equipmentItem.params ? equipmentItem.params : {};
            return (
              <ReadonlyField
                key={id}
                text={t(`eventDetails.settings.tabs.roomSetup.equipment.readOnlyView.${id}_READONLY`, {
                  amount: params.count,
                  amount_white: params.card_count_white,
                  amount_colored: params.card_count_colored,
                })}
                variant="TRUE"
              />
            );
          })}
      </SectionBlock>

      <SectionBlock>
        <DefaultLabel
          alertText={t('eventDetails.settings.tabs.roomSetup.equipmentSpecificsHint')}
          alertTitle={t('generalHintTitle')}
          label={t('eventDetails.settings.tabs.roomSetup.equipmentSpecificsLabel')}
          alertSeverity="info"
        />
        {equipmentSpecifics ? (
          <ReadonlyField text={equipmentSpecifics} variant="TEXT" />
        ) : (
          <ReadonlyField text={t('noData')} variant="EMPTY" />
        )}
      </SectionBlock>

      <SectionBlock>
        <DefaultLabel
          alertText={t('eventDetails.settings.tabs.roomSetup.notesHint')}
          alertTitle={t('generalHintTitle')}
          label={t('eventDetails.settings.tabs.roomSetup.notesLabel')}
          alertSeverity="info"
        />
        {notes ? <ReadonlyField text={notes} variant="TEXT" /> : <ReadonlyField text={t('noData')} variant="EMPTY" />}
      </SectionBlock>
    </>
  );
};

export default RoomSetupReadonly;
