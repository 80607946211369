import { FunctionComponent, useState } from 'react';
import Section from '../Common/Section';
import { useEventSettings } from '../../../Context/EventSettingsContext';
import SeminarDocumentsOnlineForm from './SeminarDocumentsOnlineForm';
import SeminarDocumentsOnsiteForm from './SeminarDocumentsOnsiteForm';
import SeminarDocumentsOnlineReadonly from './SeminarDocumentsOnlineReadonly';
import SeminarDocumentsOnsiteReadonly from './SeminarDocumentsOnsiteReadonly';
import { t } from 'i18next';

const SeminarDocuments: FunctionComponent = () => {
  const [readonly, setReadonly] = useState(true);
  const { event } = useEventSettings();

  if (!event) return <></>;

  return (
    <Section
      setReadonly={setReadonly}
      readonly={readonly}
      tabId="seminarDocuments"
      title={t('eventDetails.settings.tabs.seminarDocuments.label')}
    >
      {!readonly && (event.isOnline ? <SeminarDocumentsOnlineForm /> : <SeminarDocumentsOnsiteForm />)}

      {readonly && (event.isOnline ? <SeminarDocumentsOnlineReadonly /> : <SeminarDocumentsOnsiteReadonly />)}
    </Section>
  );
};

export default SeminarDocuments;
