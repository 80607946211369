import { createContext } from 'react';

export interface IJWTContextProps {
  getJWT: () => Promise<string | undefined | null>;
  hasRequiredGroup: (requiredGroups: string[], userGroups: string[]) => boolean;
  authenticatedContext: {
    groups: string[];
  };
}

const JWTContext = createContext<IJWTContextProps>({
  getJWT: async () => {
    return null;
  },
  hasRequiredGroup: () => {
    return false;
  },
  authenticatedContext: {
    groups: [],
  },
});

export default JWTContext;
