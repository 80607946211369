import { ColorPalette } from '@hmg-aka-lu/elevate-theme';
import { Box, Button, Grid, Paper, Skeleton, Typography } from '@mui/material';
import { FunctionComponent, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

interface IStatusContainerProps {
  buttonDisabled?: boolean;
  buttonLabel: string;
  buttonPrimary?: boolean;
  buttonUrl: string;
  label: string;
  loading: boolean;
}

export const StatusContainer: FunctionComponent<PropsWithChildren<IStatusContainerProps>> = ({
  buttonDisabled = false,
  buttonLabel,
  buttonPrimary = false,
  buttonUrl,
  children,
  label,
  loading,
}) => {
  return (
    <Box component={Paper} bgcolor={ColorPalette.surface.elevated} p={2} elevation={0} mb={2}>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Typography variant="headingXxs">{label}</Typography>
        </Grid>
        <Grid item xs={10}>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              {loading ? (
                <Box sx={{ width: '100%' }}>
                  <Skeleton />
                </Box>
              ) : (
                children
              )}
            </Grid>
            <Grid item xs={3}>
              <Button
                component={Link}
                disabled={buttonDisabled}
                reloadDocument={buttonUrl.startsWith('https://')}
                to={buttonUrl}
                variant={buttonPrimary ? 'contained' : 'outlined'}
              >
                {buttonLabel}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
