import { ColorPalette } from '@hmg-aka-lu/elevate-theme';
import { Box, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT } from '../../../../config';
import { format } from 'date-fns';

interface IKundenfragebogenStatusProps {
  contactPerson: string;
  receivedAt?: string;
  sentToCustomerAt?: string;
  status: 'NOT_SENT_TO_CUSTOMER' | 'PENDING' | 'COMPLETED_BY_CUSTOMER';
}

export const KundenfragebogenStatus: FunctionComponent<IKundenfragebogenStatusProps> = ({
  contactPerson,
  receivedAt,
  sentToCustomerAt,
  status,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Box>
        <Typography paragraph data-testid="kundenfragebogen-status">
          {t('event.overview.statusLabel')}{' '}
          <Typography
            component="span"
            data-testid="kundenfragebogen-status-value"
            sx={{ ...(status === 'COMPLETED_BY_CUSTOMER' ? { color: ColorPalette.feedback.green } : {}) }}
          >
            {t(`event.overview.kundenfragebogen.status.${status}`)}
          </Typography>
        </Typography>
      </Box>

      {sentToCustomerAt && (
        <Box mt={1}>
          <Typography paragraph data-testid="kundenfragebogen-sent-to-customer-at">
            {t('event.overview.kundenfragebogen.sentToCustomerAt')}{' '}
            <Typography component="span">{format(new Date(sentToCustomerAt), DATE_FORMAT)}</Typography>
          </Typography>
        </Box>
      )}

      {receivedAt && (
        <Box mt={1}>
          <Typography paragraph data-testid="kundenfragebogen-received-from-customer-at">
            {t('event.overview.kundenfragebogen.receivedFromCustomerAt')} {format(receivedAt, DATE_FORMAT)}
          </Typography>
        </Box>
      )}

      {status !== 'NOT_SENT_TO_CUSTOMER' && (
        <Box mt={1}>
          <Typography paragraph data-testid="kundenfragebogen-contact-person">
            {t('event.overview.kundenfragebogen.contactPerson')} {contactPerson}
          </Typography>
        </Box>
      )}
    </>
  );
};
