import { Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import EventTime from './EventTime';
import { useTranslation } from 'react-i18next';
import { DefaultLabel } from '../Common/DefaultLabel';
import SectionBlock from '../Common/SectionBlock';
import { ReadonlyField } from '../Common/ReadonlyField';
import { useEventSettings } from '../../../Context/EventSettingsContext';
import { IAblauf } from '@shared/interfaces';

interface ILocationOnlineSettings {
  ablauf: IAblauf[];
  tool: string;
  isClientTool: boolean;
}

const LocationOnlineReadonly: FunctionComponent<ILocationOnlineSettings> = ({ ablauf, tool, isClientTool }) => {
  const { t } = useTranslation();
  const { eventSettings } = useEventSettings();

  if (!eventSettings) {
    return <></>;
  }

  const { startTime } = eventSettings.location ?? {};

  return (
    <>
      {ablauf && (
        <SectionBlock>
          <DefaultLabel
            alertTitle={t('generalHintTitle')}
            label={t('eventDetails.settings.tabs.location.time')}
            alertText={t(`eventDetails.settings.tabs.location.timeHintReadOnly`)}
          />
          <EventTime ablauf={ablauf} />
        </SectionBlock>
      )}
      <SectionBlock>
        <DefaultLabel
          alertTitle={t('generalHintTitle')}
          label={t('eventDetails.settings.tabs.location.differentTimeLabel')}
          alertText={t(`eventDetails.settings.tabs.location.differentTimeHint`)}
        />
        <ReadonlyField text={startTime || ''} variant="TEXT" />
      </SectionBlock>

      <SectionBlock>
        <DefaultLabel
          alertTitle={t('generalHintTitle')}
          label={t('eventDetails.settings.tabs.location.eventTool')}
          alertText={t(`eventDetails.settings.tabs.location.eventToolHint`)}
        />

        <Typography>
          {t('eventDetails.settings.tabs.location.tool', { tool })}
          <br />
          {t('eventDetails.settings.tabs.location.responsibility', {
            responsibility: t(`eventDetails.settings.tabs.location.${isClientTool ? 'clientTool' : 'akaTool'}`),
          })}
        </Typography>
      </SectionBlock>
    </>
  );
};

export default LocationOnlineReadonly;
