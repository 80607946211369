import { useQuery } from '@tanstack/react-query';
import { getApiService } from '../api/api-request';

export const internalContactsQueryKeys = {
  contact: (email: string) => ['internal-contacts', email] as const,
};

export const useInternalContacts = (email: string, getJWT: () => Promise<string | undefined | null>) => {
  return useQuery({
    queryKey: internalContactsQueryKeys.contact(email),
    queryFn: () => getApiService(getJWT).getInternalContact(email),
  });
};
