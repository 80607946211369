import { Alert, Button } from '@mui/material';
import { FunctionComponent, useContext, useState } from 'react';
import JWTContext from '../../JWTContext';
import { useEventSettingsMutation } from '../../../queries/event-settings';
import { Box } from '@mui/system';
import { useUsersList } from '../../../queries/users';
import { useEventSettings, useEventSettingsDispatch } from '../../Context/EventSettingsContext';
import { useQueryClient } from '@tanstack/react-query';
import { Trans, useTranslation } from 'react-i18next';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ConfirmationDialog from './Common/ConfirmationDialog';
import { contactDataCompleted } from './ContactData/ContactDataForm';

const Submit: FunctionComponent = () => {
  const { eventSettings, debitorId } = useEventSettings();
  const [open, setOpen] = useState(false);

  const { getJWT } = useContext(JWTContext);
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const eventSettingsDispatch = useEventSettingsDispatch();

  const {
    mutate: updateEventSettings,
    isPending: updatingEventSettings,
    isSuccess: updateSuccess,
  } = useEventSettingsMutation(getJWT, queryClient);

  const { data: users } = useUsersList(getJWT, debitorId);

  const handleSend = (eventId: string) => {
    updateEventSettings({
      eventId,
      data: {
        status: 'SENT_TO_CUSTOMER',
      },
    });

    eventSettingsDispatch({
      type: 'change-status',
      payload: {
        status: 'SENT_TO_CUSTOMER',
      },
    });
  };

  const getRecipient = () => {
    const user = users.find((item) => item.id === recipients[0]);

    return `${user?.firstName} ${user?.lastName} (${user?.emailAddress})`;
  };

  if (!eventSettings) {
    return <></>;
  }

  const recipients = eventSettings.contactData?.contactPersonsCustomer ?? [];

  if (updateSuccess) {
    return (
      <Alert severity="success" sx={{ mb: 3 }}>
        {t('eventDetails.sentLinkPerMail')}
      </Alert>
    );
  }

  const actions = (
    <>
      <Button variant="contained" autoFocus onClick={() => handleSend(eventSettings.eventId)}>
        {t('send')}
      </Button>
      <Button variant="outlined" onClick={() => setOpen(false)}>
        {t('cancel')}
      </Button>
    </>
  );

  return (
    <Box mt={10} mb={10}>
      <Button
        variant="contained"
        disabled={updatingEventSettings || !contactDataCompleted(eventSettings.contactData)}
        startIcon={<MailOutlineIcon />}
        onClick={() => setOpen(true)}
      >
        {t('eventDetails.settings.tabs.submit.dialogTitle')}
      </Button>

      <ConfirmationDialog
        open={open}
        title={t('eventDetails.settings.tabs.submit.dialogTitle')}
        handleClose={() => setOpen(false)}
        actions={actions}
      >
        <Trans i18nKey={'eventDetails.settings.tabs.submit.dialogContent'} values={{ accessLinkASP: getRecipient() }} />
      </ConfirmationDialog>
    </Box>
  );
};

export default Submit;
