import { FunctionComponent, useContext } from 'react';
import { useActivityLogs } from '../../queries/activity-logs';
import JWTContext from '../JWTContext';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { KundenfragebogenStatus } from './Overview/Status/Kundenfragebogen';
import { EventSettingsContext } from '../Context/EventSettingsContext';
import { FeedbackStatus } from './Overview/Status/Feedback';
import { StatusContainer } from './Overview/Status/StatusContainer';
import config, { MICROFRONTEND_ID } from '../../config';
import { isFuture } from 'date-fns';

interface IEventOverviewProps {
  eventId: string;
}

export const EventOverview: FunctionComponent<IEventOverviewProps> = ({ eventId }) => {
  const { getJWT } = useContext(JWTContext);
  const { data: activityLogs, isLoading: activityLogsLoading } = useActivityLogs(eventId, getJWT);

  const { eventSettings } = useContext(EventSettingsContext);

  const { t } = useTranslation();

  const kundenfragebogenSentToCustomerActivity = activityLogs?.find(
    (item) => item.eventType === 'aka.ban.los.organizations.kundenfragebogen.sent-to-customer'
  );
  const kundenfragebogenCompletedByCustomerActivity = activityLogs?.find(
    (item) => item.eventType === 'aka.ban.los.organizations.kundenfragebogen.completed-by-customer'
  );
  const noFeedbackLinkSentDisabledActivity = activityLogs?.find(
    (item) => item.eventType === 'aka.ban.los.organizations.feedback-result-link.not-sent.feedback-disabled'
  );
  const noFeedbackLinkSentNoRecipientsActivity = activityLogs?.find(
    (item) => item.eventType === 'aka.ban.los.organizations.feedback-result-link.not-sent.no-recipients'
  );
  const noFeedbackLinkSentNoFeedbackActivity = activityLogs?.find(
    (item) => item.eventType === 'aka.ban.los.organizations.feedback-result-link.not-sent.no-feedback'
  );
  const feedbackLinkEmailScheduledActivity = activityLogs?.find(
    (item) => item.eventType === 'aka.ban.los.organizations.feedback-result-link.email-scheduled'
  );

  const getKundenfragebogenStatus = () => {
    if (kundenfragebogenCompletedByCustomerActivity) {
      return 'COMPLETED_BY_CUSTOMER';
    }

    if (!kundenfragebogenSentToCustomerActivity) {
      return 'NOT_SENT_TO_CUSTOMER';
    }

    return 'PENDING';
  };

  const getFeedbackStatus = (): FeedbackStatus => {
    if (feedbackLinkEmailScheduledActivity) {
      const scheduledAt = new Date(feedbackLinkEmailScheduledActivity.data.scheduledAt);

      return isFuture(scheduledAt) ? 'SCHEDULED' : 'SENT';
    }

    if (noFeedbackLinkSentNoFeedbackActivity) {
      return 'NOT_SENT_NO_FEEDBACK';
    }

    if (noFeedbackLinkSentNoRecipientsActivity) {
      return 'NOT_SENT_NO_RECIPIENT';
    }

    if (noFeedbackLinkSentDisabledActivity) {
      return 'NOT_SENT_DISABLED';
    }

    return eventSettings?.feedback?.enabled ? 'NOT_SENT_YET' : 'DISABLED';
  };

  const answerCount =
    feedbackLinkEmailScheduledActivity?.data.answerCount ||
    noFeedbackLinkSentDisabledActivity?.data.answerCount ||
    noFeedbackLinkSentNoRecipientsActivity?.data.answerCount;

  const overallImpression =
    feedbackLinkEmailScheduledActivity?.data.overallImpression ||
    noFeedbackLinkSentDisabledActivity?.data.overallImpression ||
    noFeedbackLinkSentNoRecipientsActivity?.data.overallImpression;

  const nps =
    feedbackLinkEmailScheduledActivity?.data.nps ||
    noFeedbackLinkSentDisabledActivity?.data.nps ||
    noFeedbackLinkSentNoRecipientsActivity?.data.nps;

  const configrationLink = `/${MICROFRONTEND_ID}/events/${eventId}/configuration`;
  const summaryLink = `/${MICROFRONTEND_ID}/events/${eventId}/configuration#summary`;
  const kundenfragebogenStatus = getKundenfragebogenStatus();
  const feedbackStatus = getFeedbackStatus();

  return (
    <>
      <Typography sx={{ mb: 2 }} variant="headingXs">
        {t('event.overview.mainHeading')}
      </Typography>

      <StatusContainer
        buttonLabel={
          kundenfragebogenStatus === 'NOT_SENT_TO_CUSTOMER'
            ? t('event.overview.configuration.gotoConfiguration')
            : t('event.overview.kundenfragebogen.gotoSummary')
        }
        buttonUrl={kundenfragebogenStatus === 'NOT_SENT_TO_CUSTOMER' ? configrationLink : summaryLink}
        label={t('event.overview.kundenfragebogen.header')}
        loading={activityLogsLoading}
      >
        <KundenfragebogenStatus
          contactPerson={kundenfragebogenSentToCustomerActivity?.data.recipient.email ?? ''}
          receivedAt={kundenfragebogenCompletedByCustomerActivity?.createDate}
          sentToCustomerAt={kundenfragebogenSentToCustomerActivity?.createDate}
          status={kundenfragebogenStatus}
        />
      </StatusContainer>

      <StatusContainer
        buttonDisabled={typeof answerCount === 'undefined'}
        buttonLabel={t('event.overview.feedback.gotoFeedbackResults')}
        buttonUrl={`${config.LOS_COCKPIT_URL}/feedbackService/feedback-report/${eventId}`}
        label={t('event.overview.feedback.header')}
        buttonPrimary={false}
        loading={activityLogsLoading}
      >
        <FeedbackStatus
          status={feedbackStatus}
          overallImpression={overallImpression ?? null}
          linkValidUntil={feedbackLinkEmailScheduledActivity?.data.linkValidUntil}
          nps={nps ?? null}
          recipients={feedbackLinkEmailScheduledActivity?.data.recipients}
          sentToCustomerAt={feedbackLinkEmailScheduledActivity?.data.scheduledAt}
        />
      </StatusContainer>
    </>
  );
};
