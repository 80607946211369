import { FunctionComponent } from 'react';
import SectionSummary from '../Common/SectionSummary';
import useSectionVisibility from '../../../../hooks/useSectionVisibility';
import { useTranslation } from 'react-i18next';
import { IEventSettingsData } from '@shared/interfaces';
import { useEventSettings } from '../../../Context/EventSettingsContext';
import EventTime from './EventTime';
import AddressReadOnly from '../Common/AddressReadOnly';
import SectionBlock, { ISummaryNotification, NOTIFICATION_TYPE } from '../Common/SectionBlock';
import { SummaryLabel } from '../Common/SummaryLabel';
import { resolveAddress } from '../../../../utils/address-resolver';
import { ReadonlyField } from '../Common/ReadonlyField';
import deepEqual from 'deep-equal';
import { getAddressForCopy } from '../Common/getAddressCopy';

const LocationOnsiteSummary: FunctionComponent = () => {
  const sectionId: keyof IEventSettingsData = 'location';
  const { isVisible } = useSectionVisibility(sectionId);
  const { t } = useTranslation();
  const { event, eventSettings, snapshots } = useEventSettings();

  if (!event || !eventSettings) {
    return <></>;
  }

  const { locationNotes, startTime } = eventSettings.location ?? {};

  const sentToCustomerSnapshot = snapshots.find((snapshot) => snapshot.snapshotType === 'SENT_TO_CUSTOMER');
  const finishedByCustomerSnapshot = snapshots.find((snapshot) => snapshot.snapshotType === 'FINISHED_BY_CUSTOMER');

  const { location: sentToCustomerLocation } = sentToCustomerSnapshot ?? {};
  const { location: finishedByCustomerLocation } = finishedByCustomerSnapshot ?? {};

  const resolvedAddress = resolveAddress(eventSettings, 'location');
  const addressForCopy = resolvedAddress
    ? getAddressForCopy({ ...resolvedAddress, country: t(`countries.${resolvedAddress.country}`) })
    : undefined;

  const getStartTimeNotification = (): ISummaryNotification | undefined => {
    if (sentToCustomerLocation?.startTime !== finishedByCustomerLocation?.startTime) {
      return {
        text: t('eventDetails.settings.tabs.summary.notifications.location.startTime.filled'),
        notificationType: NOTIFICATION_TYPE[1],
      };
    }

    return undefined;
  };

  const getLocationAddressNotification = (): ISummaryNotification | undefined => {
    if (sentToCustomerLocation?.eventLocationRef !== finishedByCustomerLocation?.eventLocationRef) {
      return {
        text: t('eventDetails.settings.tabs.summary.notifications.location.address.changed'),
        notificationType: NOTIFICATION_TYPE[1],
      };
    }

    if (
      finishedByCustomerLocation?.eventLocationRef === 'location' &&
      !deepEqual(sentToCustomerLocation?.eventLocation, finishedByCustomerLocation?.eventLocation)
    ) {
      return {
        text: t('eventDetails.settings.tabs.summary.notifications.location.address.customAddressChanged'),
        notificationType: NOTIFICATION_TYPE[1],
      };
    }

    return undefined;
  };

  return (
    <SectionSummary title={t(`eventDetails.settings.tabs.summary.${sectionId}.title.onsite`)} hidden={!isVisible}>
      <SectionBlock>
        <SummaryLabel label={t('eventDetails.settings.tabs.location.time')} />
        <EventTime ablauf={event.ablauf} />
      </SectionBlock>
      <SectionBlock data-testid="location-start-time" notification={getStartTimeNotification()}>
        <SummaryLabel label={t('eventDetails.settings.tabs.location.differentTimeLabel')} copyText={startTime} />
        <ReadonlyField text={startTime || t('noData')} variant={startTime ? 'TEXT' : 'EMPTY'} />
      </SectionBlock>
      <SectionBlock data-testid="location-address" notification={getLocationAddressNotification()}>
        <SummaryLabel label={t('eventDetails.settings.tabs.location.eventLocation')} copyText={addressForCopy} />
        <AddressReadOnly address={resolveAddress(eventSettings, 'location')} />
      </SectionBlock>
      <SectionBlock data-testid="location-notes" notification={getLocationAddressNotification()}>
        <SummaryLabel label={t('eventDetails.settings.tabs.location.locationNotes')} copyText={locationNotes} />
        <ReadonlyField text={locationNotes || t('noData')} variant={locationNotes ? 'TEXT' : 'EMPTY'} />
      </SectionBlock>
    </SectionSummary>
  );
};

export default LocationOnsiteSummary;
