import { Checkbox, FormControl, FormControlLabel, FormGroup, TextField } from '@mui/material';
import { IRoomEquipmentItem } from '@shared/interfaces';
import { ChangeEvent, FunctionComponent, useState } from 'react';
import { Trans } from 'react-i18next';
import { useEventSettingsDispatch } from '../../../Context/EventSettingsContext';

interface IEquipmentItem {
  id: string;
  params?: { id: string; defaultValue: string }[];
}

const EQUIPMENT: IEquipmentItem[] = [
  {
    id: 'U_SHAPED_TABLES',
  },
  {
    id: 'SIT_IN_CIRCLE',
  },
  {
    id: 'PARLAMENT',
  },
  {
    id: 'ADDITIONAL_SPACE_FOR_GROUP_WORK',
  },
  {
    id: 'SEPARATE_ROOM_FOR_GROUP_WORK',
  },
  {
    id: 'PROJECTOR_AND_SCREEN',
  },
  {
    id: 'FLIPCHART',
    params: [{ id: 'count', defaultValue: '1' }],
  },
  {
    id: 'PINBOARD',
    params: [
      { id: 'count', defaultValue: '1' },
      { id: 'card_count_white', defaultValue: '0' },
      { id: 'card_count_colored', defaultValue: '0' },
    ],
  },
  {
    id: 'MODERATION_PENS',
    params: [{ id: 'count', defaultValue: '1' }],
  },
  {
    id: 'CALCULATORS',
    params: [{ id: 'count', defaultValue: '1' }],
  },
];

interface IEquipmentProps {
  equipment: IRoomEquipmentItem[];
}

interface IEquipmentParams {
  [key: string]: {
    [key: string]: string;
  };
}

const Equipment: FunctionComponent<IEquipmentProps> = ({ equipment }) => {
  const eventSettingsDispatch = useEventSettingsDispatch();

  const initialEquipmentParams = equipment.reduce((acc, curr) => {
    return {
      ...acc,
      [curr.id]: curr.params,
    };
  }, {});

  const [equipmentParams, setEquipmentParams] = useState<IEquipmentParams>(initialEquipmentParams);

  const handleEquipmentParamChange = (equipmentId: string, paramId: string, value: string) => {
    const newParamValues = {
      ...equipmentParams[equipmentId],
      [paramId]: value,
    };

    const selectedEquipment = EQUIPMENT.find((equipment) => equipment.id === equipmentId) ?? ({} as IEquipmentItem);
    const otherEquipmentIdParams = selectedEquipment.params?.filter((param) => param.id !== paramId) ?? [];
    for (const params of otherEquipmentIdParams) {
      if (!newParamValues[params.id]) {
        newParamValues[params.id] = params.defaultValue;
      }
    }

    setEquipmentParams((current) => ({
      ...current,
      [equipmentId]: newParamValues,
    }));

    eventSettingsDispatch({
      type: 'update-room-setup-equipment',
      payload: { id: equipmentId, ...(Object.keys(newParamValues).length > 0 ? { params: newParamValues } : {}) },
    });
  };

  const handleCheck = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked) {
      eventSettingsDispatch({
        type: 'add-room-setup-equipment',
        payload: {
          id: event.target.value,
          params:
            EQUIPMENT.find((equipment) => equipment.id === event.target.value)?.params?.reduce((acc, curr) => {
              return { ...acc, ...{ [curr.id]: curr.defaultValue } };
            }, {}) ?? {},
        },
      });
    } else {
      eventSettingsDispatch({ type: 'remove-room-setup-equipment', payload: { id: event.target.value } });
      setEquipmentParams((current) => {
        const { [event.target.value]: _keyToBeRemoved, ...rest } = current;
        return {
          ...rest,
        };
      });
    }
  };

  const checkSelectedItem = (currentItem: IEquipmentItem) => {
    return typeof equipment.find((item) => currentItem.id === item.id) !== 'undefined';
  };

  return (
    <FormControl sx={{ mt: 0.5 }} component="fieldset" variant="standard">
      <FormGroup>
        {EQUIPMENT.map((equipmentItem) => {
          const checked = checkSelectedItem(equipmentItem);
          return (
            <FormControlLabel
              key={`room-equipment-${equipmentItem.id}`}
              control={<Checkbox checked={checked} value={equipmentItem.id} onChange={handleCheck} />}
              label={
                <Trans
                  i18nKey={`eventDetails.settings.tabs.roomSetup.equipment.formView.${equipmentItem.id}`}
                  components={equipmentItem.params?.reduce((acc, curr) => {
                    const defaultValue = curr.id === 'count' ? '1' : '0';

                    return {
                      ...acc,
                      [curr.id]: (
                        <TextField
                          disabled={!checked}
                          inputProps={{ style: { fontSize: '18px' } }}
                          InputProps={{ inputProps: { min: '0' } }}
                          value={equipmentParams[equipmentItem.id]?.[curr.id] ?? defaultValue}
                          sx={{ width: '40px' }}
                          variant="standard"
                          size="small"
                          type="number"
                          onChange={(event) =>
                            handleEquipmentParamChange(equipmentItem.id, curr.id, event.target.value)
                          }
                        />
                      ),
                    };
                  }, {})}
                />
              }
            />
          );
        })}
      </FormGroup>
    </FormControl>
  );
};

export default Equipment;
