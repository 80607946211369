import { useQuery } from '@tanstack/react-query';
import { getApiService } from '../api/api-request';

const ROOT_KEY = 'activity-logs';

export const activityLogsQueryKeys = {
  event: (eventId: string) => [ROOT_KEY, eventId] as const,
};

export const useActivityLogs = (eventId: string, getJWT: () => Promise<string | undefined | null>) => {
  return useQuery({
    queryKey: activityLogsQueryKeys.event(eventId),
    queryFn: () => getApiService(getJWT).getActivityLogs(eventId),
  });
};
