import { Box, ClickAwayListener, IconButton, Tooltip } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { t } from 'i18next';
import { IAddress } from '@shared/interfaces';

interface ICopyButtonProps {
  customHoverText?: string;
  textToCopy?: string | Partial<IAddress>;
  hidden?: boolean;
  isAddress?: boolean;
}

const CopyButton: FunctionComponent<ICopyButtonProps> = ({
  customHoverText,
  textToCopy,
  hidden = false,
  isAddress = false,
}) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const copyText = () => {
    let text;

    if (isAddress) {
      const address = textToCopy as Partial<IAddress>;
      const addressFields = [
        address.line1,
        address.line2,
        address.street,
        address.postalCode,
        address.city,
        t(`countries.${address.country}`),
      ].filter((item) => item !== undefined);

      text = addressFields.join('\n');
    } else {
      text = textToCopy as string;
    }

    navigator.clipboard.writeText(text);
    handleTooltipOpen();
  };

  const hoverText = () => {
    if (customHoverText) {
      return customHoverText;
    }
    return isAddress
      ? t('eventDetails.settings.tabs.summary.copyAddress')
      : t('eventDetails.settings.tabs.summary.copyLine');
  };

  if (hidden || !textToCopy) {
    return false;
  }

  return (
    <Box display={'inline-block'}>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
          title={open ? '' : hoverText()}
          arrow
          placement={'right'}
          PopperProps={{
            disablePortal: true,
          }}
        >
          <div>
            <Tooltip
              arrow
              placement={'right'}
              PopperProps={{
                disablePortal: true,
              }}
              onClose={handleTooltipClose}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={
                <Box display={'flex'} alignItems={'center'}>
                  <TaskAltIcon
                    sx={{
                      fontSize: '16px',
                      mr: 0.5,
                    }}
                  />{' '}
                  {t('eventDetails.settings.tabs.summary.copied')}
                </Box>
              }
            >
              <IconButton size="medium" aria-label="copy" onClick={copyText}>
                <ContentCopyIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </div>
        </Tooltip>
      </ClickAwayListener>
    </Box>
  );
};

export default CopyButton;
