import { ICreateParticipantRequestBody, IParticipant } from '@shared/interfaces';
import { parse } from 'papaparse';
import { EMAIL_REGEX } from '../../../../config';
import { t } from 'i18next';
import { columns } from './ParticipantsTable';

const PARTICIPANT_CSV_ROW_ERRORS = ['MISSING_FIRST_NAME', 'MISSING_LAST_NAME', 'INVALID_EMAIL'] as const;
export type ParticipantCsvError = (typeof PARTICIPANT_CSV_ROW_ERRORS)[number];

export interface IParticipantCsvRow {
  errors: ParticipantCsvError[];
  exists: boolean;
  id?: string;
  data: ICreateParticipantRequestBody;
}

export const parseCsvContent = (content: string, currentParticipants: IParticipant[]): IParticipantCsvRow[] => {
  const csvContent = parse<ICreateParticipantRequestBody>(content, {
    skipEmptyLines: 'greedy',
    header: true,
    complete(results) {
      const importedFields = results.meta.fields ?? [];
      const validColumns = columns.map((column) => column.id as string);

      // Validate that only columns id are included on results.meta.fields
      const areFieldsValid =
        validColumns.length === importedFields.length &&
        new Set(validColumns).size === new Set(importedFields.filter((field) => validColumns.includes(field))).size;

      if (!areFieldsValid) {
        throw new Error(t('eventDetails.settings.tabs.participants.csvImport.errorInvalidContent'));
      }
    },
    transformHeader: (header) => {
      // Map translated headers to attribute (e.g: Vorname -> firstName)
      switch (header) {
        case t('eventDetails.settings.tabs.participants.table.title'):
          return 'title';

        case t('eventDetails.settings.tabs.participants.table.firstName'):
          return 'firstName';

        case t('eventDetails.settings.tabs.participants.table.lastName'):
          return 'lastName';

        case t('eventDetails.settings.tabs.participants.table.email'):
          return 'email';

        default:
          return header;
      }
    },
  });

  const participants: ICreateParticipantRequestBody[] = csvContent.data;

  return [...participants].map((participant) => {
    const existingParticipant = currentParticipants.find(
      (item) => item.email !== '' && item.email === participant.email
    );
    return {
      data: participant,
      id: existingParticipant?.id,
      errors: [
        ...(participant.firstName?.length === 0 ? [PARTICIPANT_CSV_ROW_ERRORS[0]] : []),
        ...(participant.lastName?.length === 0 ? [PARTICIPANT_CSV_ROW_ERRORS[1]] : []),
        ...(participant.email && !participant.email.match(EMAIL_REGEX) ? [PARTICIPANT_CSV_ROW_ERRORS[2]] : []),
      ],
      exists:
        currentParticipants.findIndex(
          (item) =>
            item.email === participant.email &&
            item.firstName === participant.firstName &&
            item.lastName === participant.lastName &&
            item.title === participant.title
        ) > -1,
    };
  });
};
