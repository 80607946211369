import { FormControlLabel, Switch, Tooltip } from '@mui/material';
import { FunctionComponent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import useSectionVisibility from '../../../../hooks/useSectionVisibility';
import { useEventSettings, useEventSettingsDispatch } from '../../../Context/EventSettingsContext';
import { useEventSettingsMutation } from '../../../../queries/event-settings';
import JWTContext from '../../../JWTContext';
import { useQueryClient } from '@tanstack/react-query';
import { AlertContext } from '../../../AlertSnackbar/AlertContext';
import { Sections } from '@shared/interfaces';

interface ISectionSwitchProps {
  section: Sections;
}

export const SectionSwitch: FunctionComponent<ISectionSwitchProps> = ({ section }) => {
  const eventSettingsDispatch = useEventSettingsDispatch();
  const { t } = useTranslation();
  const { isVisible } = useSectionVisibility(section);
  const { enqueueAlert } = useContext(AlertContext);
  const { getJWT } = useContext(JWTContext);
  const queryClient = useQueryClient();
  const { mutate: updateEventSettings } = useEventSettingsMutation(getJWT, queryClient);
  const { eventSettings } = useEventSettings();

  if (!eventSettings) return <></>;

  const { eventId, hiddenSections } = eventSettings;

  const labelKey = isVisible
    ? 'eventDetails.settings.sectionSwitch.checked'
    : 'eventDetails.settings.sectionSwitch.unchecked';

  const setSectionsToUpdate = () => {
    const sectionIsHidden = hiddenSections.includes(section);

    const updatedHiddenSections = sectionIsHidden
      ? [...hiddenSections].filter((item) => item !== section)
      : [...hiddenSections, section];
    return updatedHiddenSections;
  };

  return (
    <Tooltip title={t(labelKey)}>
      <FormControlLabel
        control={
          <Switch
            checked={isVisible}
            onChange={() => {
              updateEventSettings(
                { eventId, data: { hiddenSections: setSectionsToUpdate() } },
                {
                  onError: () => {
                    enqueueAlert({
                      message: t('eventDetails.settings.alerts.updateError'),
                      type: 'error',
                    });
                  },
                  onSuccess: () => {
                    eventSettingsDispatch({ type: 'toggle-hidden-section', payload: section });
                    eventSettingsDispatch({ type: 'update-event-settings-original' });
                    eventSettingsDispatch({ type: 'reset-dirty-state' });
                    enqueueAlert({
                      message: t('eventDetails.settings.alerts.updateSuccess'),
                      type: 'success',
                    });
                  },
                }
              );
            }}
          />
        }
        label={t('eventDetails.settings.sectionSwitch.label')}
      />
    </Tooltip>
  );
};
