import { FunctionComponent } from 'react';
import SectionSummary from '../Common/SectionSummary';
import useSectionVisibility from '../../../../hooks/useSectionVisibility';
import { useTranslation } from 'react-i18next';
import { IEventSettingsData } from '@shared/interfaces';
import { useEventSettings } from '../../../Context/EventSettingsContext';
import { ReadonlyField } from '../Common/ReadonlyField';
import { SummaryLabel } from '../Common/SummaryLabel';
import SectionBlock, { ISummaryNotification, NOTIFICATION_TYPE } from '../Common/SectionBlock';
import deepEqual from 'deep-equal';

const ConfirmationOfParticipationSummary: FunctionComponent = () => {
  const sectionId: keyof IEventSettingsData = 'confirmationOfParticipation';
  const { isVisible } = useSectionVisibility(sectionId);
  const { t } = useTranslation();

  const { eventSettings, snapshots } = useEventSettings();
  const { enabled, recipientEmailAddresses } = eventSettings?.confirmationOfParticipation ?? {};

  const sentToCustomerSnapshot = snapshots.find((snapshot) => snapshot.snapshotType === 'SENT_TO_CUSTOMER');
  const finishedByCustomerSnapshot = snapshots.find((snapshot) => snapshot.snapshotType === 'FINISHED_BY_CUSTOMER');

  const { confirmationOfParticipation: sentToCustomerConfirmationOfParticipation } = sentToCustomerSnapshot ?? {};
  const { confirmationOfParticipation: finishedByCustomerConfirmationOfParticipation } =
    finishedByCustomerSnapshot ?? {};

  const getTBsEnabledNotification = (): ISummaryNotification | undefined => {
    if (finishedByCustomerConfirmationOfParticipation?.enabled === true) {
      return {
        text: t('eventDetails.settings.tabs.summary.notifications.confirmationOfParticipation.enabled.true'),
        notificationType: NOTIFICATION_TYPE[0],
      };
    }

    return undefined;
  };

  const getRecipientEmailAddressesNotification = (): ISummaryNotification | undefined => {
    if (
      !deepEqual(
        sentToCustomerConfirmationOfParticipation?.recipientEmailAddresses,
        finishedByCustomerConfirmationOfParticipation?.recipientEmailAddresses
      )
    ) {
      return {
        text: t(
          'eventDetails.settings.tabs.summary.notifications.confirmationOfParticipation.recipientEmailAddresses.changed'
        ),
        notificationType: NOTIFICATION_TYPE[1],
      };
    }

    return undefined;
  };

  return (
    <SectionSummary title={t(`eventDetails.settings.tabs.summary.${sectionId}.title`)} hidden={!isVisible}>
      <SectionBlock data-testid="tbs-enabled" notification={getTBsEnabledNotification()}>
        <SummaryLabel label={t('eventDetails.settings.tabs.confirmationOfParticipation.certificationLabel')} />
        {(enabled === null || enabled === undefined) &&
          t('eventDetails.settings.tabs.confirmationOfParticipation.unknownForNow')}
        {enabled === true && (
          <ReadonlyField
            text={t('eventDetails.settings.tabs.confirmationOfParticipation.enabledLabel')}
            variant="TRUE"
          />
        )}
        {enabled === false && (
          <ReadonlyField
            text={t('eventDetails.settings.tabs.confirmationOfParticipation.disabledLabel')}
            variant="FALSE"
          />
        )}
      </SectionBlock>

      {enabled && (
        <SectionBlock
          data-testid="tbs-recipient-email-addresses"
          notification={getRecipientEmailAddressesNotification()}
        >
          <SummaryLabel
            label={t('eventDetails.settings.tabs.confirmationOfParticipation.recipientAddressLabel')}
            copyText={
              recipientEmailAddresses?.[0]
                ? t('eventDetails.settings.tabs.confirmationOfParticipation.recipientAddressLabel') +
                  ': ' +
                  recipientEmailAddresses[0]
                : undefined
            }
          />
          <ReadonlyField
            text={recipientEmailAddresses?.[0] || t('missingData')}
            error={!recipientEmailAddresses?.[0]}
            variant="TEXT"
          />
        </SectionBlock>
      )}
    </SectionSummary>
  );
};

export default ConfirmationOfParticipationSummary;
