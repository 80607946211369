import { IconButton, Typography } from '@mui/material';
import { FunctionComponent, ReactNode, useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { AlertItem } from './AlertItem';

interface IAlertItemProps {
  label: string;
  alertText?: string | ReactNode;
  alertTitle?: string;
  alertSeverity?: 'info' | 'error' | 'warning' | 'success';
}

export const DefaultLabel: FunctionComponent<IAlertItemProps> = ({
  alertSeverity = 'info',
  alertTitle,
  alertText,
  label,
}) => {
  const [hintVisibility, setHintVisibility] = useState(false);

  return (
    <>
      <Typography variant="headingXs" alignItems={'center'} mb={0}>
        {label}
        <IconButton
          sx={{ ml: 0.5, color: 'black' }}
          onClick={() => {
            setHintVisibility(!hintVisibility);
          }}
        >
          <InfoOutlinedIcon />
        </IconButton>
      </Typography>

      <AlertItem
        alertSeverity={alertSeverity}
        alertText={alertText}
        alertTitle={alertTitle}
        enableAlert={hintVisibility}
        setEnableAlert={setHintVisibility}
      />
    </>
  );
};
