import { FunctionComponent, useState } from 'react';
import Section from '../Common/Section';
import RoomSetupReadonly from './RoomSetupReadonly';
import RoomSetupForm from './RoomSetupForm';
import { t } from 'i18next';

const ContactData: FunctionComponent = () => {
  const [readonly, setReadonly] = useState(true);

  return (
    <Section
      setReadonly={setReadonly}
      readonly={readonly}
      tabId="roomSetup"
      title={t('eventDetails.settings.tabs.roomSetup.label')}
    >
      {readonly ? <RoomSetupReadonly /> : <RoomSetupForm />}
    </Section>
  );
};

export default ContactData;
