import { FunctionComponent } from 'react';
import { Checkbox, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useEventSettings, useEventSettingsDispatch } from '../../../Context/EventSettingsContext';
import { DefaultLabel } from '../Common/DefaultLabel';
import EmailRecipient from '../Common/EmailRecipient';
import SectionBlock from '../Common/SectionBlock';
import AddressMultiForm, { ISelectableAddress } from '../Common/AddressMultiForm';
import { IAddress, SeminarDocumentsFormat } from '@shared/interfaces';

export const showAddressForm = (format: SeminarDocumentsFormat | undefined, additionalDocuments: boolean) => {
  return format === 'print' || additionalDocuments;
};

const SeminarDocumentsOnlineForm: FunctionComponent = () => {
  const { t } = useTranslation();
  const { eventSettings } = useEventSettings();
  const eventSettingsDispatch = useEventSettingsDispatch();

  if (!eventSettings) return <></>;

  const { additionalDocuments, format, recipientAddress, sendWritingMaterials, recipientAddressRef } =
    eventSettings.seminarDocuments ?? {};

  const addressHintTranslationKeySuffix = recipientAddress ? 'customAddress' : 'debitorAddress';

  const contactDataAddress = eventSettings.contactData?.customerAddress;

  const selectableContactDataAddress: ISelectableAddress | undefined = contactDataAddress && {
    address: contactDataAddress,
    key: 'contactData',
    label: t('eventDetails.settings.tabs.contactData.customerAddress'),
    onSelect: () =>
      eventSettingsDispatch({
        type: 'update',
        payload: { seminarDocuments: { recipientAddressRef: 'contactData' } },
      }),
    selected: recipientAddressRef === 'contactData',
  };

  const selectableAddresses: ISelectableAddress[] = [
    ...(selectableContactDataAddress ? [selectableContactDataAddress] : []),
  ];

  return (
    <>
      <SectionBlock>
        <DefaultLabel
          alertTitle={t('generalHintTitle')}
          label={t('eventDetails.settings.tabs.seminarDocuments.formatLabel')}
          alertText={t('eventDetails.settings.tabs.seminarDocuments.formatHint')}
        />
        <FormControl>
          <RadioGroup
            aria-labelledby="radio-buttons-seminar-documents-format"
            value={typeof format !== 'undefined' ? format : null}
            name="radio-buttons-location"
          >
            <FormControlLabel
              disabled={true}
              key="radio-buttons-seminar-documents-format-digital"
              value="digital"
              control={<Radio />}
              label={t('eventDetails.settings.tabs.seminarDocuments.formats.digital')}
            />
            <FormControlLabel
              disabled={true}
              key="radio-buttons-seminar-documents-format-print"
              value="print"
              control={<Radio />}
              label={t('eventDetails.settings.tabs.seminarDocuments.formats.print')}
            />
            <FormControlLabel
              disabled={true}
              key="radio-buttons-seminar-documents-format-noSu"
              value="noSU"
              control={<Radio />}
              label={t('eventDetails.settings.tabs.seminarDocuments.formats.noSU')}
            />
          </RadioGroup>
        </FormControl>
      </SectionBlock>

      <SectionBlock>
        <DefaultLabel
          alertTitle={t('generalHintTitle')}
          label={t('eventDetails.settings.tabs.seminarDocuments.additionalDocuments.label')}
          alertText={t('eventDetails.settings.tabs.seminarDocuments.additionalDocumentsHint')}
        />
        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={additionalDocuments ?? false}
                onChange={(_event, checked: boolean) =>
                  eventSettingsDispatch({
                    type: 'update',
                    payload: { seminarDocuments: { additionalDocuments: checked } },
                  })
                }
              />
            }
            label={t('eventDetails.settings.tabs.seminarDocuments.additionalDocuments.additionalDocumentsCheckbox')}
          />
        </FormControl>
      </SectionBlock>

      <SectionBlock>
        <DefaultLabel
          alertTitle={t('generalHintTitle')}
          label={t('eventDetails.settings.tabs.seminarDocuments.sendWritingMaterialsLabel')}
          alertText={t('eventDetails.settings.tabs.seminarDocuments.sendWritingMaterialsHint')}
        />
        <FormControlLabel
          control={
            <Checkbox
              disabled={format === 'digital'}
              checked={sendWritingMaterials ?? false}
              onChange={(_event, checked: boolean) =>
                eventSettingsDispatch({
                  type: 'update',
                  payload: { seminarDocuments: { sendWritingMaterials: checked } },
                })
              }
            />
          }
          label={t('eventDetails.settings.tabs.seminarDocuments.sendWritingMaterials.sendWritingMaterialsCheckbox')}
        />
      </SectionBlock>

      {format === 'digital' && (
        <SectionBlock>
          <EmailRecipient
            title={t('eventDetails.settings.tabs.seminarDocuments.emailRecipientsLabel')}
            hintText={<Trans i18nKey={'eventDetails.settings.tabs.seminarDocuments.recipientEmailHintReadonly'} />}
            section="seminarDocuments"
            requiredInput
          />
        </SectionBlock>
      )}
      {showAddressForm(format, !!additionalDocuments) && (
        <SectionBlock>
          <DefaultLabel
            alertTitle={t('generalHintTitle')}
            label={t('eventDetails.settings.tabs.seminarDocuments.chooseRecipientAddressLabel')}
            alertText={t(
              `eventDetails.settings.tabs.seminarDocuments.recipientAddressHint.${addressHintTranslationKeySuffix}`
            )}
          />
          <AddressMultiForm
            customAddress={recipientAddress}
            customAddressSelected={eventSettings.seminarDocuments?.recipientAddressRef === 'seminarDocuments'}
            onChangeCustomAddress={(address: Partial<IAddress> | undefined) =>
              eventSettingsDispatch({
                type: 'update',
                payload: { seminarDocuments: { recipientAddress: address, recipientAddressRef: 'seminarDocuments' } },
              })
            }
            onRemoveCustomAddress={() => {
              const newRef = recipientAddressRef === 'seminarDocuments' ? 'contactData' : recipientAddressRef;
              eventSettingsDispatch({
                type: 'update',
                payload: { seminarDocuments: { recipientAddressRef: newRef, recipientAddress: undefined } },
              });
            }}
            onSelectCustomAddress={() =>
              eventSettingsDispatch({
                type: 'update',
                payload: { seminarDocuments: { recipientAddressRef: 'seminarDocuments' } },
              })
            }
            selectableAddresses={selectableAddresses}
          />
        </SectionBlock>
      )}
    </>
  );
};

export default SeminarDocumentsOnlineForm;
