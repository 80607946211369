import { Button, Dialog, DialogActions, DialogTitle, IconButton } from '@mui/material';
import { Dispatch, FunctionComponent, SetStateAction, useContext } from 'react';
import { t } from 'i18next';
import { useEventSettings, useEventSettingsDispatch } from '../Context/EventSettingsContext';
import { useEventSettingsMutation } from '../../queries/event-settings';
import JWTContext from '../JWTContext';
import { useQueryClient } from '@tanstack/react-query';
import { AlertContext } from './AlertContext';
import CloseIcon from '@mui/icons-material/Close';

export interface IAlert {
  message: string;
  setVisibility: Dispatch<SetStateAction<boolean>>;
  callBackFn: () => void;
  openAlert: Dispatch<SetStateAction<boolean>>;
}

const AlertSave: FunctionComponent<IAlert> = ({ message, setVisibility, callBackFn, openAlert }) => {
  const { eventSettings } = useEventSettings();
  const queryClient = useQueryClient();
  const eventSettingsDispatch = useEventSettingsDispatch();

  const { getJWT } = useContext(JWTContext);

  const { mutate: updateEventSettings, isPending: saving } = useEventSettingsMutation(getJWT, queryClient);

  const { enqueueAlert } = useContext(AlertContext);

  if (!eventSettings || 'snapshotType' in eventSettings) return <></>;
  const { eventId, completenessCheck: _completenessCheck, status: _status, ...rest } = eventSettings;

  const handleClose = () => {
    openAlert(false);
  };

  return (
    <Dialog open={true} fullWidth={true} onClose={handleClose}>
      <DialogTitle
        sx={{
          pr: 10,
        }}
      >
        {message}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 10,
          top: 10,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogActions sx={{ mb: 2, mx: 2 }}>
        <Button
          variant="outlined"
          disabled={saving}
          onClick={() => {
            eventSettingsDispatch({ type: 'reset-context' });
            setVisibility(false);
            callBackFn();
          }}
        >
          {t('eventDetails.saveAlertBox.reset')}
        </Button>
        <Button
          variant="contained"
          autoFocus
          disabled={saving}
          onClick={() => {
            updateEventSettings(
              { eventId, data: rest },
              {
                onError: () => {
                  enqueueAlert({
                    message: t('eventDetails.settings.alerts.updateError'),
                    type: 'error',
                  });
                },
                onSuccess: () => {
                  eventSettingsDispatch({ type: 'update-event-settings-original' });
                  eventSettingsDispatch({ type: 'reset-dirty-state' });
                  callBackFn();
                  enqueueAlert({
                    message: t('eventDetails.settings.alerts.updateSuccess'),
                    type: 'success',
                  });
                },
                onSettled: () => {
                  setVisibility(false);
                },
              }
            );
          }}
        >
          {t('eventDetails.saveAlertBox.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertSave;
