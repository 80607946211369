import { FunctionComponent, useState } from 'react';
import Section from '../Common/Section';
import { useEventSettings } from '../../../Context/EventSettingsContext';
import ConfirmationOfParticipationForm from './ConfirmationOfParticipationForm';
import ConfirmationOfParticipationReadonly from './ConfirmationOfParticipationReadonly';
import { t } from 'i18next';

const ConfirmationOfParticipation: FunctionComponent = () => {
  const [readonly, setReadonly] = useState(true);
  const { event } = useEventSettings();

  if (!event) return <></>;

  return (
    <Section
      setReadonly={setReadonly}
      readonly={readonly}
      tabId="confirmationOfParticipation"
      title={t('eventDetails.settings.tabs.confirmationOfParticipation.label')}
    >
      {readonly ? <ConfirmationOfParticipationReadonly /> : <ConfirmationOfParticipationForm />}
    </Section>
  );
};

export default ConfirmationOfParticipation;
